// @flow

import format from 'date-fns/format';
import { formatToTimeZone } from 'date-fns-timezone';
import numeral from 'numeral';
import { SvgIcon } from './SvgIcon';
import { cn } from './lib/classNames';

import type { SvgIconProps } from './SvgIcon';
import type { Icon } from '../../assets/icons';
import type { BbImageSourceTrigger } from '../../api/type';

export const RightArrow = (props: $Rest<SvgIconProps, { svg: Icon }>): React$Node => (
    <SvgIcon svg={'caret-right'} className='c-resource-list__action' {...props} />
);

export const formatTime = (date: ?(Date | number)): string => date ? format(date, 'H:mm') : '-';
export const formatDateTime = (date: ?(Date | number)): string => date ? format(date, 'd LLL yyyy H:mm') : '-';
export const formatUtcDateTime = (date: Date): string => formatToTimeZone(date, 'ddd DD MMM YYYY H:mm', { timeZone: 'UTC' }) + ' UTC';
export const formatDate = (date: ?Date): string => date ? format(date, 'd LLL yyyy') : '-';
export const formatResourceCost = (value: string | number): string => numeral(value).format('0,0[.]00');
export const formatHourlyResourceCost = (value: string | number): string => Number(value) > 20 ? numeral(value).format('0,0') : numeral(value).format('0,0.00[0]');
export const formatGbp = (value: ?(string | number | typeof numeral)): string => value == null ? '-' : numeral(value).format('0,0.00');

const BYTES_TO_TB = 1024 * 1024 * 1024 * 1024;
export const BYTES_TO_GB = 1024 * 1024 * 1024;
export const BYTES_TO_MB = 1024 * 1024;
const BYTES_TO_KB = 1024;
export const GB_TO_MB = 1024;

export const displaySize = (size: number): string => size >= 1024 ? numeral(size / 1024).format('0,00.00') + 'GB' : `${size}MB`;
export const displayBytesSize = (size: number): string =>
    size >= BYTES_TO_TB
        ? numeral(size / (BYTES_TO_TB)).format('0,00.00') + 'TB'
        : size >= BYTES_TO_GB
            ? numeral(size / (BYTES_TO_GB)).format('0,00.00') + 'GB'
            : size >= BYTES_TO_MB
                ? numeral(size / (BYTES_TO_MB)).format('0,00.00') + 'MB'
                : size >= BYTES_TO_KB
                    ? numeral(size / BYTES_TO_KB).format('0,00.00') + 'KB'
                    : size > 0
                        ? size + 'B'
                    : '0';

export const wholeSize = (size: number): string => size >= 1024 ? numeral(size / 1024).format('0,00.[00]') + 'GB' : `${size}MB`;

const iconMap = {
    'accepted': 'c-status--green',
    'active': 'c-status--green',
    'available': 'c-status--green',
    'blocked': 'c-status--orange',
    'cancelled': 'c-status--red',
    'creating': 'c-status--green c-status--animate',
    'deleted': 'c-status--red',
    'deleting': 'c-status--red c-status--animate',
    'ended': 'c-status--red',
    'failed': 'c-status--red',
    'failing': 'c-status--red',
    'inactive': 'c-status--orange',
    'invalid': 'c-status--red',
    'loading': 'c-status--gray',
    'mapped': 'c-status--green',
    'mapping': 'c-status--green',
    'pending': 'c-status--orange c-status--animate',
    'rejected': 'c-status--red',
    'reserved': 'c-status--orange',
    'revoked': 'c-status--red',
    'unchecked': 'c-status--gray',
    'unmapped': 'c-status--gray',
    'expired': 'c-status--gray',
    'unmapping': 'c-status--orange',
    'valid': 'c-status--green',
    'attached': 'c-status--green',
    'detached': 'c-status--gray',
    'unavailable': 'c-status--gray',
    'deprecated': 'c-status--gray',
    'paid': 'c-status--green',
    'unpaid': 'c-status--orange',
    'overdue': 'c-status--orange',
    'warning': 'c-status--orange',
    'suspended': 'c-status--orange',
    'closed': 'c-status--red',
    'terminated': 'c-status--red',
};

export const StatusIcon = ({ status }: { status: string }): React$Node => (
    <>
            <span className={'c-status c-status--for-avatar ' + (iconMap[status] || 'c-status--unknown')}>
                <span className='c-status__icon' title={status}></span>
            </span>
    </>
);

export const Status = ({ status: statusIn, revoked_at, ...rest }: { status: ?string, revoked_at?: ?Date, }): React$Node => {
    const status = statusIn || 'loading';

    let classes = {
        'c-status': true,
        'u-animate--fade-in-out': status === 'creating',
    }

    return (
        <>
            <span className={cn(classes) + ' ' + (iconMap[status] || 'c-status--unknown')} {...rest}>
                <span className='c-status__icon' title={status}>&nbsp;</span>
                <span className='c-status__text'>
                    {status.substring(0, 1).toUpperCase()}{status.substring(1)}
                    {status === 'revoked' && revoked_at
                        ? <span className='text-gray-600 ml-4'>(at {formatDateTime(revoked_at)})</span>
                        : null
                    }
                </span>
            </span>
        </>
    );

};

export function mapSourceType(source_type: string, trigger: BbImageSourceTrigger): string {
    if (source_type === 'snapshot') return 'Snapshot' + (trigger === 'manual' ? ' (manual)' : ' (scheduled)');
    if (source_type === 'upload') return 'Image';
    return source_type;
}

export const Spacer = (props: Object): React$Node => (
    <div className='mb-8' {...props} />
);

export const protocolLabel = {
    'tcp': 'TCP',
    'udp': 'UDP',
    'any': 'Any',
    'icmp': 'ICMP',
};

export const formatOrbitSize = (size: number): string => {
    if (size === 0) {
        return '-';
    } else {
        return displayBytesSize(size);
    }
};

export const formatMegabytesSize = (size: number): string => {
    return size >= 1024 ? numeral(size / 1024).format('0,00[.]0[0]') + 'GB' : `${size}MB`
};

export const AcmeDomain = ({ domain }: { domain: string }): React$Node => <span className='c-acme-domain'>{domain}</span>;

export const Bullet = (props: Object): React$Node => (
    <span className='mx-1 text-gray-400' {...props}>•</span>
);

export const Bar = (props: Object): React$Node => (
    <div className='mx-4 border-gray-400 border-r inline-block align-middle w-1 h-8' {...props}></div>
);

export const AddCardText = (): React$Node => (
    <p className="mb-6">Please add your card details below. We'll use them now to validate
        your account and later for billing if you choose to continue after your trial credit is used up.</p>
);