import logo from '../../assets/brightbox-logo.svg';

export const Logo = ({ caption }: { caption?: string }): React$Node => {
    return (
        <div style={{ maxWidth: '100px', 'margin': '1em auto', 'textAlign': 'center' }}>
            <img src={logo} alt='Brightbox Systems Ltd'/><br/>
            {caption}
        </div>
    );
};
