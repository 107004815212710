// @flow
import { useState } from 'react';
import { Avatar } from './Avatar';
import { CopyLink } from './Button';
import { Pill } from './Pill';
import { Tooltip } from './Tooltip';
import { ImageCard } from './ImageCard';
import { useResourcesById } from '../hoc/ListPage';
import ClampLines from './ClampLines';
import { SvgIcon } from './SvgIcon';
import { useViewResource } from '../hoc/ViewResource';
import { SkeletonChip } from './Skeleton';
import { getResourceKind } from '../../api/lib';
import { Link } from 'react-router-dom';
import { useBuildingResource } from '../hoc/Build';
import { formatOrbitSize, formatMegabytesSize, Bullet } from './Styled';
import { useResourceRoutes } from '../../lib/history';
import { useDatabaseEngineTypes } from '../hoc/Database';
import { RC_NOT_FOUND } from "../../state/resource/type";

import type { BbAllResourceTypes, BbResourceKind, BbServerGroup, BbNestedServerGroup, BbUser, BbNestedUser, BbCollaboration, } from '../../api/type';
import type { AvatarProps } from './Avatar';
import type { BbFirewallRule } from '../../api/type.fwp';
import type { BbCollectedServer } from '../../api/type.srv';
import type { LocationShape } from 'react-router';
import type { BbCollectedAccount, BbAccount } from '../../api/type.acc';
import type { OrbitBulkDeletion } from '../../state/Build/type';
import type { ImageTag } from "../hoc/orbit/ContainerRegistry";
import type { Icon } from "../../assets/icons";

type ChipProps = {
    ...AvatarProps,
    name?: ?string,
    kind?: BbResourceKind,
    copyValue?: ?string,
    official?: boolean,
    isNew?: boolean,
    isDefault?: boolean,
    isBootVolume?: boolean,
    isImagesContainer?: boolean,
    isPending?: boolean,
    owner?: boolean,
    dark?: boolean,
    inline?: boolean,
    titleLines?: number,
    avatarOverlay?: ?React$Node,
    className?: ?string,
    link?: ?(string | LocationShape),
    contentType?: ?string,
    secondary?: ?string,
    concise?: boolean,
    current?: boolean,
}

const TRIM_LENGTH = 50;

export function trimString(value: string, length: number = TRIM_LENGTH): string {
    let result = value.substring(0, length - 1).trim();
    if (value.length >= length) {
        result += '...';
    }
    return result;

}

export function getTrimmedName(name: React$Node, length: number = TRIM_LENGTH): React$Node {
    if (typeof name === 'string') {
        return trimString(name, length);
    }

    return name;
}

let clampLinesId = 0;

export const Chip = ({ name, id, status, icon, imageName, engine, initial, titleLines, copyValue, official, owner, dark, isDefault, isBootVolume, isNew, isImagesContainer, isPending, progress, colorId, gravatar_url, avatarOverlay, className, link, inline, secondary, contentType, concise, current, ...rest }: ChipProps): React$Node => {
    const [clampId] = useState((clampLinesId++).toString());
    const displayName = name || id || '-';
    const chipName = <ClampLines
                        id={clampId}
                        text={displayName}
                        lines={titleLines || 2}
                        buttons={false}
                        delay={100}
                        ellipsis='…'
                        widthSelector='.c-chip'
                    />;

    const avatar: ?React$Node = contentType || icon || imageName || engine || initial || gravatar_url
        ? <Avatar
            id={id}
            icon={icon}
            contentType={contentType}
            imageName={imageName}
            engine={engine}
            initial={initial}
            status={status}
            colorId={colorId}
            gravatar_url={gravatar_url}
            progress={progress}
            className='c-chip__avatar'
        />
        : null;

    return (
        <div className={'c-chip' + (dark ? ' c-chip--dark': '') + (inline ? ' c-chip--inline': '') + (typeof className === 'string' ? ' ' + className : '')} {...rest}>
            {avatar && avatarOverlay
                ? <Tooltip background='light' overlay={avatarOverlay}>{avatar}</Tooltip>
                : null}
            {avatar && !avatarOverlay
                ? avatar
                : null}
            {typeof id === 'string' || typeof name === 'string'
                ? <div className='c-chip__content'>
                    <h4 className='c-chip__title' title={(typeof name === 'string' && name.length >= TRIM_LENGTH) ? name : null } >
                        {link
                            ? <Link to={link} className='c-chip__link'>{chipName}</Link>
                            : chipName
                        }
                        {isBootVolume? <Tooltip overlay={<span>This Volume is configured as the boot volume for the Cloud Server</span>}><SvgIcon svg='flag' className='c-chip__special'/></Tooltip> : null}
                        {isDefault ? <Tooltip overlay={<span>The <b>default</b> Server Group is a special group to which all Cloud Servers are added (unless specified otherwise)</span>}><SvgIcon svg='star' className='c-chip__special'/></Tooltip> : null}
                        {isImagesContainer ? <Tooltip overlay={<span>The <b className='text-white'>images</b> container is a special Orbit container where Cloud Server and Cloud SQL snapshots are stored</span>}><SvgIcon svg='star' className='c-chip__special' /></Tooltip> : null}
                        {copyValue ? <CopyLink value={copyValue}/> : null}
                    </h4>
                    <span className='c-chip__secondary'>
                    {id
                        ? <span className='c-identifier'>{id}</span>
                        : null}
                    {current ? <Pill>Current</Pill> : null}
                    {official ? <Pill>Official</Pill> : null}
                    {owner ? <Pill>Owner</Pill> : null}
                    {isPending ? <Pill>Pending</Pill> : null}
                    {secondary && id && !concise
                        ? <Bullet/>
                        : null}
                    {secondary && !concise
                        ? <span className='text-xs select-all'>{secondary}</span>
                        : null}
                    {isNew ? <div className='absolute top-0 right-0 m-2'><Pill>New</Pill></div> : null}
                    </span>
                </div>
                : null}
        </div>
    );
};

const getFirewallRuleName = (s: BbFirewallRule): string => {
    let parts = [];
    if (s.source !== null) {
        parts.push('Inbound: allow');
    } else {
        parts.push('Outbound: allow');
    }
    if (s.icmp_type_name) {
        parts.push('ICMP ' + s.icmp_type_name);
    }
    if (s.source !== null) {
        parts.push('from', s.source);
    }
    if (s.source_port !== null) {
        parts.push('from port', s.source_port);
    }
    if (s.destination !== null) {
        parts.push('to', s.destination);
    }
    if (s.destination_port !== null) {
        parts.push('to port', s.destination_port);
    }

    return parts.join(' ');
};

function getCollabStatus(collab: BbCollaboration) {
    if (collab.finished_at != null) {
        return 'deleted';
    }
    if (collab.started_at == null) {
        return 'pending';
    }
    return 'active';
}

function extractInitial(id: string, name: ?string): string {
    return name ? name.substring(0, 1).toUpperCase() : id.substring(4, 5).toUpperCase()
}

export function initial(resource: BbServerGroup | BbNestedServerGroup | BbAccount | BbUser | BbNestedUser |  BbCollectedAccount): string {
    return extractInitial(resource.id, resource.name);
}

type ResourceChipProps = {
     ...ChipProps,
    resource: BbAllResourceTypes,
    hideAccountStatus?: boolean,
}

// Used in ResourceChip when we have the 'full' resource. ResourceChip sometimes
// ignores this mapping to override eg DB server -> uses an engine icon.
// Used in ResourceChipFetch where the resource might be deleted and unavailable in the API,
// so it has non-clever fallback icons.
// Overall, it's not too bad if resource types are missing here; it's only truly useful
// for deleted resources, and even some of those hang around as `status: "deleted"` in the
// API for long enough not to need the fallback in ResourceChipFetch.
const ICON_MAP = new Map<BbResourceKind, Icon>([
    ['server', 'resource/cpu'],
    ['load_balancer', 'resource/load-balancer'],
    ['database_server', 'resource/cloud-sql'],   // fallback; ResourceChip usually examines the engine.
    ['firewall_rule', 'resource/firewall'],      // fallback; ResourceChip usually examines the direction
    ['image', 'resource/image'],                 // fallback; ResourceChip knows image vs snapshot
    ['cloud_ip', 'resource/cloud-ip'],
    ['application', 'code'],
    ['api_client', 'braces'],
    ['database_snapshot', 'resource/snapshot'],
    ['account', 'ellipsis-horizontal'],          // fallback; ResourceChip uses initial
    ['volume', 'resource/volume'],
]);

function getIconProps(id: string, name: ?string, kind: BbResourceKind): $Shape<ResourceChipProps> {
    if (kind === 'server_group' || kind === 'user') {
        return {
            initial: extractInitial(id, name),
        }
    }

    const icon = ICON_MAP.get(kind);

    return icon ? { icon } : null;
}

export const ResourceChip = ({ resource, hideAccountStatus, ...rest }: ResourceChipProps): React$Node => {
    const { resources: servers } = useResourcesById<BbCollectedServer>('server');
    const { engineLabel } = useDatabaseEngineTypes();

    if (resource == null) { return null; }

    const iconProps = getIconProps(resource.id, resource.name, resource.resource_type);

    switch(resource.resource_type) {
    case 'server':
        const srvImageName = (servers?.[resource.id]?.image || resource.image || null)?.name;
        return (
            <Chip
                id={resource.id}
                name={resource.name}
                imageName={srvImageName}
                {...iconProps}
                status={resource.status || null}
                avatarOverlay={resource.image
                    ? <ImageCard image={resource.image} /> /* only check resource.image here - so the overlay does not show for Nested reprs */
                    : null}
                {...rest}
            />
        );
    case 'load_balancer':
        return (
            <Chip
                name={resource.name}
                id={resource.id}
                status={resource.status || null}
                {...iconProps}
                {...rest}
            />
        );
    case 'database_server':
        return (
            <Chip
                id={resource.id}
                name={resource.name}
                secondary={engineLabel(resource.database_engine, resource.database_version)}
                engine={resource.database_engine || null}
                status={resource.status || null}
                {...rest}
            />
        );
    case 'server_group':
        return (
            <Chip
                id={resource.id}
                name={resource.name}
                isDefault={resource.default || false}
                {...iconProps}
                {...rest}
            />
        );
    case 'firewall_rule':
        return (
            <Chip
                id={resource.id}
                name={(resource.source || resource.destination) ? getFirewallRuleName(resource) : null}
                icon={resource.source !== null ? 'resource/firewall-inbound' : 'resource/firewall-outbound'}
                {...rest}
            />
        );
    case 'image':
        return (
            <Chip
                id={resource.id}
                name={resource.name}
                official={resource.official || false}
                imageName={resource.name}
                status={resource.status}
                icon={resource.source_type === 'snapshot' ? 'resource/snapshot' : 'resource/image'}
                {...rest}
            />
        );
    case 'cloud_ip':
        return (
            <Chip
                name={resource.name}
                id={resource.id}
                secondary={resource.public_ipv4}
                status={resource.status || null}
                {...iconProps}
                {...rest}
            />
        );
    case 'application':
        return (
            <Chip
                name={resource.name}
                id={resource.id}
                status={resource.status || null}
                {...iconProps}
                {...rest}
            />
        );
    case 'api_client':
        return (
            <Chip
                id={resource.id}
                name={resource.name}
                status={resource.revoked_at !== null ? 'deleted' : 'active'}
                {...iconProps}
                {...rest}
            />
        );
    case 'database_snapshot':
        return (
            <Chip
                name={resource.name}
                id={resource.id}
                secondary={engineLabel(resource.database_engine, resource.database_version)}
                status={resource.status || null}
                {...iconProps}
                {...rest}
            />
        );
    case 'account':
        return (
            <Chip
                id={resource.id}
                colorId={resource.id}
                name={resource.name}
                initial={resource.name.length ? resource.name[0] : null}
                icon={resource.name.length === 0 ? 'ellipsis-horizontal' : null}
                status={hideAccountStatus ? null : resource.status}
                {...rest}
            />
        );
    case 'user':
        return (
            <Chip
                id={resource.id}
                colorId={resource.id}
                name={resource.name}
                gravatar_url={resource.gravatar_url}
                {...iconProps}
                {...rest}
            />
        );
    case 'collaboration':
        return (
            <Chip
                name={resource.user?.name || resource.email}
                id={resource.user?.id || resource.id}
                colorId={resource.user?.id}
                status={getCollabStatus(resource)}
                initial={(resource.user?.name || resource.user?.email_address || resource.email).substr(0, 1).toUpperCase()}
                owner={resource?.role === 'owner'}
                gravatar_url={resource.user?.gravatar_url}
                link={resource.id + '/'}
            />
        );
    case 'volume':
        return (
            <Chip
                name={resource.name}
                id={resource.id}
                status={resource.status || null}
                isBootVolume={resource.boot}
                secondary={`${formatMegabytesSize(resource.size)}`}
                {...iconProps}
                {...rest}
            />
        );
    default:
        return (
            <Chip
                id={resource.id}
                name={resource.name || resource.id}
                {...rest}
            />
        );
    }
};

type ResourceChipFetchProps = {
    ...ChipProps,

    id: string,
    kind?: BbResourceKind,
    fallback?: ?BbAllResourceTypes,
};

export const ResourceChipFetch = ({ id, kind, fallback, ...rest }: ResourceChipFetchProps): React$Node => {
    const buildProgress = useBuildingResource(id);
    const viewKind = kind || getResourceKind(id);
    const view = useViewResource(viewKind, id);

    const progress: $Shape<ChipProps> = buildProgress !== null ? { progress: buildProgress } : {}

    if (view.item != null){
        return <ResourceChip resource={view.item} {...progress} {...rest} />
    } else if (fallback != null) {
        return <ResourceChip resource={fallback} {...progress} {...rest} />
    } else if (view.status === RC_NOT_FOUND) {
        return <Chip id={id} kind={kind} {...getIconProps(id, null, kind)} />
    }

    return <SkeletonChip />
}

type OrbitContainerChipProps = {
    ...ChipProps,
    +name: string,
    +bytes?: number,
    +deleteProgress?: OrbitBulkDeletion,
}

export function OrbitContainerChip({ name, bytes, deleteProgress, ...rest }: OrbitContainerChipProps): React$Node {
    const getRoute = useResourceRoutes();
    let id: ?string = null;

    if (deleteProgress) {
        id = `Deleted ${deleteProgress.completed} objects`;
    } else if (bytes != null) {
        id = formatOrbitSize(bytes);
    }

    const isContainerRegistry = name.endsWith('_ctrimages');

    return (
        <Chip
            name={name}
            secondary={id}
            icon={
                name === 'images'
                    ? 'resource/image'
                    : isContainerRegistry ? 'registry' : 'resource/orbit'
            }
            isImagesContainer={name === 'images'}
            link={getRoute(isContainerRegistry ? 'container_registry' : 'container', name)}
            {...rest}
        />
    );
}

type DockerImageChipProps = {
    ...ChipProps,
    imageTag: ImageTag,
}

export function DockerImageChip({ imageTag, ...rest }: DockerImageChipProps): React$Node {
    const { name, nameTag, sha256, tagUrl } = imageTag;
    return (
        <Chip
            name={nameTag}
            id={sha256.substring(0, 12)}
            /* colorId has to have a 'resource type' prefix, so... do this. */
            colorId={'dockerimage_' + name}
            copyValue={tagUrl}
            icon='registry'
            {...rest}
        />
    );
}