// @flow
import { cn } from '../../element/lib/classNames';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../../element/SvgIcon';
import { Tooltip } from '../../element/Tooltip';
import { Button } from '../../element/Button';
import { useResourceRoutes } from '../../../lib/history';

import type { OnboardingHook, } from '../../hoc/Onboarding';

type OnboardCheckListProps= {
    onboarding: OnboardingHook,
}

export const OnboardCheckList = ({ onboarding }: OnboardCheckListProps): React$Node => {
    const getRoute = useResourceRoutes();

    // prevent steps from slowly completing as resources are fetched
    if (onboarding.loading) return null;

    return (
        <div>
            <ul className='c-checklist'>
            {onboarding.steps.map(step => (
                <li key={step.id} className={cn({
                    'c-checklist__item': true,
                    'c-checklist__item--completed': step.completed,
                })}>
                <Tooltip overlay={!step.completed ? 'Mark as completed?' : 'Completed'}>
                <div className='c-checklist__tick' onClick={!step.completed ? () => onboarding.onHideFlag(step.id) : null}>
                    <SvgIcon
                        svg={'tick'}
                        className='c-checklist__tick-icon'
                    />
                </div>
                </Tooltip>
                {step.link && !step.completed
                    ? <>
                        <Link to={step.link} className='c-checklist__item-link'>
                            <div className='c-checklist__item-name'>
                                {step.title}
                            </div>
                            <SvgIcon
                                svg='caret-right'
                                className='c-checklist__item-arrow'
                            />
                        </Link>
                    </>
                    : <span className='c-checklist__item-name'>{step.title}</span>
                }
                </li>
            ))}
            </ul>
            <div className='mt-6 mb-1'>
            <Link to={getRoute('support')}>
                <Button size='sm' className='w-full'>Need help to get started?</Button>
            </Link>
            </div>
        </div>
    )
}