// @flow

import { useDialog } from '../../element/Dialog';
import type { MessageHook } from '../Messages';
import { useTriggeredMessages } from '../Messages';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { RC_API_REQUEST, RC_SUCCESS } from '../../../state/resource/type';
import to from 'await-to-js';
import { useCurrentAccountId } from '../lib';
import { fetchOrbitAuth } from '../../../state/Orbit/lib';
import { orbitUrl } from '../../../api/url';
import { orbit } from '../../../api/rest';
import { toast } from 'react-toastify';
import { orbitObjectExists } from './Object';

import type { DialogState } from '../../element/Dialog';
import type { Dispatch } from 'redux';
import type { MessageStateAction } from '../../../state/Message/type';

export type UploadableFile = {
    file: File,
    contentType: string,
    validSize: boolean,
    alreadyExists: boolean,
    overwrite: boolean,
}
type OrbitFileUploadFn = (filename: string, content: string, contentType: string, onProgress: (progress: number) => void) => Promise<null>;

const useOrbitFileUpload = (containerName: string): OrbitFileUploadFn => {
    const accountId = useCurrentAccountId();

    return async (filename: string, content: string, contentType: string, onProgress: (progress: number) => void): Promise<null> => {
        await fetchOrbitAuth();
        const dest = [orbitUrl, accountId, containerName, filename].join('/');
        let [err,] = await to(orbit({
            url: dest,
            method: 'PUT',
            headers: {
                'content-type': contentType,
            },
            data: content,
            onUploadProgress: (e) => {
                onProgress(e.loaded / e.total);
            }
        }));

        if (err) {
            toast(
                'Error uploading: ' + filename,
                { type: 'err', }
            );
        }

        return null;
    };
};

export type OrbitFileUploadHook = {
    hoverClass: boolean,
    confirmUploadDialog: DialogState<Array<UploadableFile>>,
    uploadMessages: MessageHook<null>,
    setFileValue: (index: number, file: UploadableFile) => void,
    uploadProgress: $ReadOnlyArray<?number>,
    uploadableFiles: Array<UploadableFile>;
}
// 10mb limit for now
export const MAX_UPLOAD_SIZE = 1024 * 1024 * 10;

export function useFileUpload(containerName: string, path: string, refresh: () => void): OrbitFileUploadHook {
    const dispatch = useDispatch<Dispatch<MessageStateAction>>();
    const { next, messages: uploadMessages } = useTriggeredMessages();
    const uploadFile = useOrbitFileUpload(containerName);
    const [uploadProgress, setUploadProgress] = useState<Array<?number>>([]);
    const accountId = useCurrentAccountId() || '';
    const [uploadableFiles, setUploadableFiles] = useState<Array<UploadableFile>>([]);

    const setFileValue = (i: number, file: UploadableFile) => {
        if (i < uploadableFiles.length) {
            setUploadableFiles((data) => {
                return [
                    ...data.slice(0, i),
                    file,
                    ...data.slice(i + 1)
                ]
            });
        }
    };

    const setFileProgress = (i: number, progress: number) => setUploadProgress((all: $ReadOnlyArray<?number>) => [].concat(
        i ? all.slice(0, i) : [],
        progress,
        all.slice(i + 1),
    ));

    const confirmUploadDialog = useDialog<Array<UploadableFile>>([
        {
            label: 'Upload',
            disabled: () => {
                // noinspection UnnecessaryLocalVariableJS
                const hasAnyUploadableFiles = uploadableFiles.reduce((acc, file) => {
                    return acc && file.validSize && (!file.alreadyExists || file.overwrite);
                }, true);
                return !hasAnyUploadableFiles;
            },
            kind: 'primary',
            onSelect: async () => {
                const id = next();

                if (uploadableFiles && uploadableFiles.length) {
                    setUploadProgress(Array(uploadableFiles.length).fill(0));

                    uploadableFiles.forEach((data, i) => {
                        if (data.alreadyExists && !data.overwrite) {
                            setFileProgress(i, 1.0);
                        }
                    });

                    dispatch({
                        type: 'MESSAGE_MESSAGE',
                        payload: {
                            id,
                            status: RC_API_REQUEST,
                        }
                    });
                    for (let i = 0; i < uploadableFiles.length; i++) {
                        if (
                            !uploadableFiles[i].validSize ||
                            (uploadableFiles[i].alreadyExists && !uploadableFiles[i].overwrite)
                        ) {
                            continue;
                        }

                        const uf = uploadableFiles[i];
                        await to(new Promise(resolve => {
                            const reader = new FileReader();
                            reader.onprogress = (e: ProgressEvent) => {
                                if (e.lengthComputable) {
                                    setFileProgress(i, 0.5 * e.loaded / e.total);
                                }
                            };
                            reader.onload = async (e: ProgressEvent) => {
                                // $FlowFixMe the target of a ProgressEvent does have a .result...
                                await uploadFile(path + uf.file.name, e.target.result, uf.contentType, (progress: number) => {
                                    setFileProgress(i, 0.5 + (0.5 * progress));
                                });
                                resolve();
                            };
                            reader.onabort = resolve;
                            reader.readAsArrayBuffer(uf.file);
                        }));
                    }
                    dispatch({
                        type: 'MESSAGE_MESSAGE',
                        payload: {
                            id,
                            status: RC_SUCCESS,
                        }
                    });
                    refresh();
                }
            }
        },
    ]);

    const [hoverClass, setHoverClass] = useState<boolean>(false);

    useEffect(() => {
        const { body } = document;
        if (body) {
            const handleDragOver = (e: DragEvent) => {
                if (e.dataTransfer) {
                    e.dataTransfer.dropEffect = 'copy';
                }
                e.stopPropagation();
                e.preventDefault();
                setHoverClass(true);
            };
            const handleDragExit = (e: DragEvent) => {
                setHoverClass(false);
            };
            const handleDrop = (e: DragEvent) => {
                e.stopPropagation();
                e.preventDefault();

                setHoverClass(false);

                if (e.dataTransfer && e.dataTransfer.files.length && containerName !== 'images') {
                    const rawFiles = Array.from(e.dataTransfer.files);
                    const data = rawFiles.map((file: File) => ({
                        file,
                        contentType: file.type || 'application/octet-stream',
                        validSize: file.size < MAX_UPLOAD_SIZE,
                        alreadyExists: false,
                        overwrite: false,
                    }: UploadableFile));

                    data.forEach(async (toUpload, i) => {
                        const alreadyExists = await orbitObjectExists(accountId, containerName, path + toUpload.file.name);
                        if (alreadyExists) {
                            setUploadableFiles((data) => {
                                return [
                                    ...data.slice(0, i),
                                    {
                                        ...data[i],
                                        alreadyExists: true,
                                    },
                                    ...data.slice(i + 1)
                                ]
                            });
                        }
                    });

                    setUploadableFiles(data);
                    confirmUploadDialog.show();
                    setUploadProgress([]);
                }
            };

            body.addEventListener('dragover', handleDragOver);
            body.addEventListener('dragexit', handleDragExit);
            body.addEventListener('dragleave', handleDragExit);
            body.addEventListener('drop', handleDrop);

            return () => {
                body.removeEventListener('dragover', handleDragOver);
                body.removeEventListener('dragexit', handleDragExit);
                body.removeEventListener('dragleave', handleDragExit);
                body.removeEventListener('drop', handleDrop);
            };
        }
        // we only do this on mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        hoverClass,
        confirmUploadDialog,
        uploadMessages,
        setFileValue,
        uploadProgress,
        uploadableFiles,
    };

}