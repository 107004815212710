// @flow
import { useEffect } from 'react';
import { LoginSidebar } from './Sidebar';
import { useLogin } from '../hoc/Login';
import { PasswordInput, TextInput } from '../element/Input';
import { Layout } from './Layout';
import { Button } from '../element/Button';
import { Notice } from '../element/Notice';
import { Link } from 'react-router-dom';
import { useEscapeBackNav, pushEscHandler, removeEscHandler } from '../../lib/history';
import { Separator } from '../element/Separator';

import type { LoginProps } from '../hoc/Login';

export type UsernamePasswordFormProps = {
    login: LoginProps,
    disableUsername?: true,
}

export const UsernamePasswordForm = ({ login, disableUsername }: UsernamePasswordFormProps): React$Node => {
    return (
        <>
            {disableUsername
                ? null
                : <h1 className='c-auth__heading'>Sign in to your account</h1>
            }

            {login.error !== ''
                ? <Notice type='error' className='mb-6'>{login.error}</Notice>
                : null}

            {disableUsername
                ? null
                : <TextInput
                        label='Email'
                        id='username'
                        autoComplete='username'
                        type='email'
                        value={login.username}
                        onChange={(value: string) => login.setField('username', value)}
                        autoFocus={!disableUsername && login.username === ''}
                        className='c-auth__input'
                        disabled={login.processing}
                    />
            }
            <div className='c-auth__input'>
                <PasswordInput
                    label='Password'
                    id='password'
                    autoComplete='current-password'
                    value={login.password}
                    autoFocus={disableUsername && login.username !== ''}
                    onChange={(value: string) => login.setField('password', value)}
                    disabled={login.processing}
                />
               <p className='text-xs text-right mr-3 mt-1'><Link className='text-gray-600' to='/forgotten_password'>Forgot password?</Link></p>

            </div>

        </>
    );
};

export const TwoFactorForm = (login: LoginProps): React$Node => {
    useEscapeBackNav();
    useEffect(() => {
        const h = () => login.logout();
        pushEscHandler(h);
        return () => removeEscHandler(h);
        //  only run this on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <h2 className='c-auth__heading'>Two-Factor Authentication</h2>

            {login.error !== ''
                ? <Notice type='error' className='mb-6'>{login.error}</Notice>
                : null}

            <TextInput
                label='One Time Password'
                value={login.twoFactorCode}
                onChange={(value: string) => login.setField('twoFactorCode', value)}
                autoFocus={true}
                autoComplete='one-time-code'
                className='c-auth__input'
            />

        </>
    );
};

export const LoginBase = (): React$Node => {
    const login = useLogin();

    return (
        <Layout
            kind='auth'
            sidebar={<LoginSidebar/>}
            content={
                <div className='c-auth'>
                    <form className='c-auth__form' onSubmit={login.tryLogin}>
                        {login.twoFactor
                            ? <TwoFactorForm {...login} />
                            : <UsernamePasswordForm login={login}/>
                        }

                        <Button
                            kind='primary'
                            type="submit"
                            className='c-auth__button'
                            onClick={login.tryLogin}
                            state={login.processing ? 'loading' : ''}
                        >
                        {login.twoFactor
                            ? 'Continue'
                            : 'Sign in'
                        }
                        </Button>
                        {login.twoFactor
                            ? <Button
                                kind='tertiary' type='submit' color='grey'
                                className='c-auth__button'
                                onClick={login.logout}
                            >
                                Cancel
                            </Button>
                            : <>
                                <Separator>Don't have a Brightbox account?</Separator>
                                <Link to='/signup'>
                                    <Button
                                        className='c-auth__button'
                                    >
                                        Create an Account
                                    </Button>
                                </Link>
                            </>
                        }


                    </form>
                </div>
            }
        />

    );
};
