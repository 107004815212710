// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { to } from 'await-to-js';
import { request } from '../../api/rest';
import { accountsUrl } from '../../api/url';
import { setLoginStateFromScopes, fetchUserAndAccount } from '../../state/auth/AuthLogic';
import { RC_INITIAL } from '../../state/resource/type';

import type { Dispatch } from 'redux';
import type { AuthAction, AuthField } from '../../state/auth/type';
import type { CloudGuiState } from '../../state/cloudgui';

export type LoginProps = {
    username: string,
    password: string,
    twoFactorCode: string,
    twoFactor: boolean,
    processing: boolean,
    error: string,
    tryLogin: (e: SyntheticEvent<*>) => *,
    logout: (e?: SyntheticEvent<*>) => void,
    setField: (field: AuthField, value: string) => *,
};

export const twoFactorValidation: RegExp = /^[0-9]{6}|[0-9]{8}$/;

export async function doLoginCheck(dispatch: Dispatch<AuthAction>) {
    dispatch({ type: 'AUTH_SET_FIELD', payload: { processing: true, } });

    const [err, accountResponse] = await to(request({
        method: 'GET',
        nested: false,
        accountId: false,
        url: accountsUrl,
    }));

    if (!err && accountResponse.status === 200) {
        let nextScopes = setLoginStateFromScopes(dispatch, accountResponse.headers['x-oauth-scopes']);
        fetchUserAndAccount(dispatch, nextScopes);
    } else {
        dispatch({ type: 'AUTH_SET_FIELD', payload: { status: 'invalid', } });
    }

    dispatch({ type: 'AUTH_SET_FIELD', payload: { processing: false, } });
}

export function useLoginCheck(): void {
    const dispatch = useDispatch<Dispatch<AuthAction>>();
    const [status, accountsStatus] = useSelector((state: CloudGuiState) => [state.Auth.status, state.Resource.account.fetched]);

    useEffect(() => {
        // only run this if the app hasn't already checked login status;
        // otherwise we might just be moving between /account and /signup.
        if (status === 'unchecked' || accountsStatus === RC_INITIAL) {
            // noinspection JSIgnoredPromiseFromCall
            doLoginCheck(dispatch);
        }
        // only run this on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export const useLogin = (): LoginProps => {
    const dispatch = useDispatch<Dispatch<AuthAction>>();

    return useSelector((state: CloudGuiState) => {
        const { username, password, twoFactorCode } = state.Auth;
        const twoFactor = state.Auth.scopes.has('auth:two_factor');

        return ({
            username,
            password,
            twoFactorCode,
            twoFactor,
            processing: state.Auth.processing,
            error: state.Auth.error,
            setField: (field: AuthField, value: string) => dispatch({
                type: 'AUTH_SET_FIELD',
                payload: {
                    // $FlowFixMe the definition here saves us, wait for a better fix for flow #8177
                    [field]: value
                }
            }),
            logout: (e: *) => {
                dispatch({
                    type: 'AUTH_LOGOUT',
                    payload: {
                        clearState: true,
                    }
                });
            },
            tryLogin: (e: *) => {
                if (twoFactor && !twoFactorCode.match(twoFactorValidation)) {
                    dispatch({
                        type: 'AUTH_SET_FIELD',
                        payload: {
                            error: 'Please enter a two-factor code.',
                        }
                    });
                } else if (!twoFactor && (state.Auth.username === '' || state.Auth.password === '')) {
                    dispatch({
                        type: 'AUTH_SET_FIELD',
                        payload: {
                            error: 'Please enter both your email address and password to sign in',
                        }
                    });

                } else {
                    dispatch({ type: 'AUTH_TRY_LOGIN' });
                }

                e.preventDefault();
            },
        })
    });
}