// @flow
import { useState } from 'react';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { useCreateResource } from '../../hoc/CreateResource';
import { Notice } from '../../element/Notice';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../element/Panel';
import { TextInput } from '../../element/Input';
import { historyBack } from '../../../lib/history';
import { LABELS } from '../../element/ResourceLabels';

import type { BbCollaboration, BbCollaborationParams, } from '../../../api/type';
import type { Match } from 'react-router-dom';

export const CollaborationCreate = ({ match }: { match: Match }): React$Node => {
    const { createResource, messages, status } = useCreateResource<BbCollaboration, BbCollaborationParams>('collab', 'collaboration');
    const [params, setParams] = useState<BbCollaborationParams>({ email: '', role: 'admin', });

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.user.listTitle}
            dialog={null}
            match={match}
            view={
                <Panel>
                    <PanelHeader title='Invite Team Member'/>
                    <PanelBar>
                        <TextInput
                            value={params.email}
                            onChange={(email: string) => setParams({ ...params, email })}
                            label='Email Address'
                            autoFocus={true}
                        />
                        {messages.length
                            ? <Notice type='error'>{messages[0]}</Notice>
                            : null
                        }
                    </PanelBar>
                    <PanelButtonBar
                        invite={() => createResource(params)}
                        cancel={historyBack}
                        cacheStatus={status}
                    />
                </Panel>}
        />
    );

};
