// @flow
import { useEffect } from 'react';
import { usePendingCollaborations } from '../../hoc/Collaboration';
import { Button } from '../../element/Button';
import { RC_SUCCESS, RC_INITIAL, RC_CACHED, RC_API_REQUEST } from '../../../state/resource/type';
import { useResourceRoutes, history } from '../../../lib/history';
import { Chip } from '../../element/Chip';
import { Card } from '../../element/Card';

function useSignUpCollabs() {
    const getRoute = useResourceRoutes();
    const pending = usePendingCollaborations();
    const { collaborations, messages, cacheStatus } = pending;

    useEffect(() => {
        if (messages && messages.status === RC_SUCCESS && messages.resource) {
            if (messages.resource.status === 'accepted') {
                history.push(getRoute('dashboard', messages.resource?.account?.id));
            }
            messages.clear();
        }
    }, [messages, getRoute,]);

    useEffect(() => {
        if (cacheStatus === RC_CACHED && collaborations.length === 0) {
            history.push('/');
        }
    }, [cacheStatus, collaborations]);

    return {
        collab: collaborations.length ? collaborations[0] : null,
        ...pending,
    };
}

export const CollabSignUp = (): React$Node => {
    const { collab, accept, reject, messages, } = useSignUpCollabs();

    return (
        <div className="c-auth">
            {collab
                ? <>
                    <div className="c-auth__form">

                        <h1 className="c-auth__heading">
                        {collab.account?.name !== ''
                            ? 'Join the ' + collab.account?.name + ' account?'
                            : 'Accept Invitation from ' + collab.inviter?.name + '?'
                        }
                        </h1>

                        <Card className='c-auth__invite-card' selected={true}>
                            <Chip
                                name={collab.inviter?.name}
                                id={collab.inviter?.email_address}
                                colorId={collab.inviter?.id}
                                initial={(collab.inviter?.name || collab.inviter?.email_address).substr(0, 1).toUpperCase()}
                                gravatar_url={collab.inviter?.gravatar_url}
                            />
                        </Card>

                        <p className='mb-10'>{collab.inviter?.name} has invited you to access to their {<b>{collab.account?.name}</b>} Brightbox account as a collaborator.</p>

                        <Button
                            kind='primary'
                            type='submit'
                            onClick={() => {accept(collab);}}
                            disabled={messages?.status !== RC_INITIAL}
                            state={messages?.status === RC_API_REQUEST ? 'loading' : ''}
                            className='c-auth__button'
                        >Accept Invitation</Button>

                        <Button
                            color='grey'
                            onClick={() => {reject(collab);}}
                            disabled={messages?.status !== RC_INITIAL}
                            state={messages?.status === RC_API_REQUEST ? 'loading' : ''}
                            className='c-auth__button'
                        >
                            Decline Invitation
                        </Button>
                    </div>
                </>
                : null
            }
        </div>
    );
};