// @flow

import type { AuthStateAction, AuthState } from './type';
import type { StoreClearAction } from '../type';

let nextAccountCacheKey = 1;

const emptyState = {
    status: 'unchecked',
    username: '',
    password: '',
    twoFactorCode: '',
    scopes: new Set(),
    processing: false,
    error: '',
    currAccountId: '',
    cookieTimeout: null,
    eventsConnected: false,
    accountCacheKey: 0,
};

export const AuthReducer = (
    state: AuthState = emptyState,
    action: AuthStateAction | StoreClearAction
): AuthState => {
    switch(action.type) {
    case 'STORE_CLEAR':
        if (state.cookieTimeout) {
            state.cookieTimeout.clear();
        }
        return {
            ...emptyState,
            status: 'invalid',
        };
    case 'AUTH_SET_FIELD':
        return {
            ...state,
            // $FlowFixMe the definition here saves us, wait for a better fix for flow #8177
            ...action.payload,
        };
    case 'AUTH_SELECT_ACCOUNT':
        return {
            ...state,
            currAccountId: action.payload.account ? action.payload.account.id : '',
            accountCacheKey: ++nextAccountCacheKey,
        };
    default:
        void (action: empty);
        break;
    }

    return state;
};