// @flow
import { PanelMultiSettingsBar, } from '../../element/Panel';
import { SkeletonBar } from '../../element/Skeleton';
import { EU_COUNTRIES_LIST } from './lib';
import { summariseTempUrlKeys } from '../orbit/OrbitContainerAdvanced';
import { ResourceChip } from '../../element/Chip';
import { useCurrentUser } from '../../hoc/lib';

import type { BbAccount, } from '../../../api/type.acc';
import type { OrbitAccountMeta } from '../../../api/type.orbit';

export type AccountDetailsOwnProps = {
    +account: ?BbAccount,
    +accountMeta: ?OrbitAccountMeta,
    +tempKeyEditUri: string,
};

const AccountAddress = ({ account }: { account: BbAccount }): React$Node => {
    return (
        [account.address_1, account.address_2, account.city, account.county, account.postcode, account.country_name]
            .filter(s => s !== '').join(', ')
    );
};

export const isEuVatCountry = (country_code: string): boolean => ((EU_COUNTRIES_LIST.indexOf(country_code)) !== -1);

export const AccountDetails = (props: AccountDetailsOwnProps): React$Node => {
    const { account, accountMeta, tempKeyEditUri } = props;
    const currUser = useCurrentUser();

    const eu = account && isEuVatCountry(account.country_code)
        ? [
            {
                name: 'EU VAT Number',
                summary: account ? account.vat_registration_number || 'Not provided' : <SkeletonBar size='md'/>,
                route: 'vat_registration_number',
                hint: 'EU-based VAT registered businesses can provide a valid EU VAT number to enable invoicing without VAT being added'
            }
        ]
        : [];

    const close = currUser?.id === account?.owner?.id
        ? [[
            {
                name: 'Close Account',
                summary: '',
                route: 'close',
                settingsBtn: {
                    color: 'red',
                    children: 'Close',
                },
            },
        ]]
        : [];

    return (
        <>
            <PanelMultiSettingsBar
                details={{
                    id: account?.id,
                    status: account?.status,
                    created_at: account?.created_at,
                }}
                settings={[
                    [
                        {
                            name: 'Owner',
                            summary: account ? <ResourceChip resource={account.owner} /> : null,
                            hint: 'The account owner is the individual user who has responsibility for this Brightbox account'
                        },
                        {
                            name: 'Account Name',
                            summary: account ? account.name || '-' : <SkeletonBar size='sm'/>,
                            route: 'name',
                            hint: 'The Account Name will normally be the name of your company or organisation and, if provided, is included on your invoices (optional)'
                        },
                        {
                            name: 'Billing Address',
                            summary: account ? <AccountAddress account={account}/> : <SkeletonBar size='lg'/>,
                            route: 'address',
                            hint: 'This is the address which will be displayed on your invoices (optional)'
                        },
                        {
                            name: 'Orbit Temporary URL Keys',
                            summary: accountMeta ? summariseTempUrlKeys(accountMeta, 'account') : <SkeletonBar size='md'/>,
                            route: tempKeyEditUri,
                            hint: 'Temporary URL Keys are used to generate shareable links to Orbit objects. Keys can be created at the Account level or at the individual Orbit Container level',
                            settingsBtn: accountMeta && accountMeta.tempUrlKey1 == null && accountMeta.tempUrlKey2 == null
                                ? { children: 'Add Keys', kind: 'secondary' }
                                : { children: 'Edit' },
                        },
                        ...eu,
                    ],
                    ...close,
                ]
                }
            >
            </PanelMultiSettingsBar>
        </>
    );
};
