// @flow
import cryptoRandomString from 'crypto-random-string';
import { useCurrentAccount } from '../lib';
import { AccountPending, AccountClosed } from '../../element/LimitTooltip';

export const genTempUrlKey = (): string => cryptoRandomString({ length: 10, type: 'url-safe' });

export const useAccountStatusCreateOverlays = (resourceName: string): ?React$Node => {
    const account = useCurrentAccount();

    if (account?.status === 'pending') return <AccountPending resourceName={resourceName}/>;
    if (account?.status === 'closed') return <AccountClosed/>;

    return null;
};