// @flow
import { LoginBase } from './Login';
import { ManageBase } from './Manage';
import { useSelector } from 'react-redux';
import { PreLoginCheckBase } from './PreLoginCheckBase';
import { Route, Router, Switch } from 'react-router';
import { history } from '../../lib/history';
import { ForgottenPassword, ResetPassword } from './ResetPassword';
import { useSegment } from '../../segment';
import { useLoginCheck } from '../hoc/Login';
import { SignUp } from './SignUp';
import { Logout } from './Logout';
import { useCheckBeta } from '../../beta';
import { usePlausible } from '../../plausible';
import { useControlPanelHtmlTitles } from '../hoc/lib';

import type { CloudGuiState } from '../../state/cloudgui';
import type { AuthStatus } from '../../state/auth/type';

const statusMap: Map<AuthStatus, React$StatelessFunctionalComponent<{}>> = new Map([
    ['unchecked', PreLoginCheckBase],
    ['invalid', LoginBase],
    ['valid', ManageBase]
]);

const ManageApp = () => {
    // it's tempting to move this to AppComponent, but I don't think we should
    // be doing the login check when opening eg /reset_password, so keep it here
    useLoginCheck();
    const status = useSelector((state: CloudGuiState) => state.Auth.status);
    const Component = statusMap.get(status);

    return (
        Component
            ? <div className="App"><Component/></div>
            : <div>Unknown auth status</div>
    );
};


const AppComponent = () => {
    useSegment();
    usePlausible();
    useCheckBeta();
    useControlPanelHtmlTitles();

    return (
        <Switch>
            <Route path="/reset_password" component={ResetPassword}/>
            <Route path="/forgotten_password" component={ForgottenPassword}/>
            <Route path="/signup" component={SignUp} />
            <Route path='/logout/' component={Logout}/>
            <Route component={ManageApp}/>
        </Switch>
    );
};

const TopRouter = () => (
    <Router history={history}>
        <AppComponent/>
    </Router>
);

export const App: React$StatelessFunctionalComponent<{}> = () => <TopRouter />;
