// @flow

import { Link } from 'react-router-dom';
import { TextInput, PasswordInput } from '../../element/Input';
import { Checkbox } from '../../element/Checkbox';
import { Button } from '../../element/Button';
import { useUserSignUp } from '../../hoc/SignUp';
import { Notice } from '../../element/Notice';
import { RC_API_REQUEST } from '../../../state/resource/type';

export const UserSignUp = (): React$Node => {
    const { errors, status, user, setUser, onSignUp, } = useUserSignUp();
    const submitError = errors.get('submit');
    const userExists = errors.get('user_exists');

    return (
        <div className='c-auth'>
            <form className='c-auth__form' onSubmit={null}>

                <h1 className='c-auth__heading'>Let's get started!</h1>

                {submitError
                    ? <Notice type='error' className='mb-6'>{submitError}</Notice>
                    : null
                }
                {userExists
                    ? <Notice type='error' className='mb-6'>That email is already in use – would you like to <Link to='/'>sign in instead?</Link></Notice>
                    : null
                }

                <TextInput
                    label='Your Name'
                    value={user.name}
                    onChange={(name) => setUser({ ...user, name })}
                    errorText={errors.get('user_name')}
                    className='c-auth__input'
                />
                <TextInput
                    label='Email Address'
                    value={user.email_address}
                    onChange={(email_address) => setUser({ ...user, email_address })}
                    errorText={errors.get('user_email_address')}
                    className='c-auth__input'
                />

                <div className='c-auth__input'>
                    <PasswordInput
                        id='new-password'
                        label='Create a Password'
                        autoComplete='new-password'
                        value={user.password0}
                        onChange={(password0) => setUser({ ...user, password0 })}
                        errorText={errors.get('user_password0')}
                        helperText='at least 8 characters'
                    />
                </div>

                <div className='c-auth__input'>
                    <Checkbox
                        checked={user.terms}
                        onChange={(terms) => setUser({ ...user, terms })}
                        label={<span>I agree to the <a href='https://www.brightbox.com/legal/terms/' rel='noreferrer noopener' target='_blank'>Terms & Conditions</a></span>}
                        errorText={errors.get('user_terms')}
                        size='sm'
                        className='ml-2'
                    />
                </div>

                <Button
                    kind='primary'
                    onClick={() => { onSignUp(); }}
                    state={status === RC_API_REQUEST ? 'loading': ''}
                    className='c-auth__button'
                >
                    Create My Account
                </Button>

                <p className='text-center my-2'>Already have an account? <Link to='/login'>Sign in</Link></p>
            </form>
        </div>
    );
};
