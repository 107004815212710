// @flow
import { useState, } from 'react';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../element/Panel';
import { TextInput } from '../../element/Input';
import { useAuthedMutation } from '../../hoc/graphql';
import { ErrorList } from '../../common/ErrorList';
import { apiClient } from '../../../api/graphql';
import { useStripe, } from '@stripe/react-stripe-js';
import { processStripe } from './lib';
import { Notice } from '../../element/Notice';
import { RC_API_REQUEST, RC_CACHED } from '../../../state/resource/type';
import { historyBack } from '../../../lib/history';
import { toast } from 'react-toastify';
import { formatGbp } from '../../element/Styled';
import { SkeletonBar } from '../../element/Skeleton';
import { Button } from '../../element/Button';
import { CHARGE_BILLING_METHOD_MUTATION } from '../../hoc/Billing';
import { isNetworkRequestInFlight, } from '@apollo/client/core/networkStatus';
import { Link } from 'react-router-dom';

import type { StripeResult } from './lib';
import type { AccountBillingData } from '../../hoc/Billing';
import type { NetworkStatus } from '@apollo/react-hooks';

export type TakePaymentProps = {
    +accountId: string,
    +currentBalance: number,
    +billingData: ?AccountBillingData,
    +billingDataStatus: NetworkStatus,
};

async function takePayment(
    stripe: any,
    accountId: string,
    currentBalance: number,
    paymentAmount: string,
    chargeBillingMethod: any
): Promise<StripeResult> {
    paymentAmount = paymentAmount.replace(/,/g, '');

    if (isNaN(paymentAmount)
        || parseFloat(paymentAmount) > parseFloat(currentBalance)
        || parseFloat(paymentAmount) < 0.3
    ) {
        return { success: false, error: 'Please enter valid payment amount between £1.00 and £' + currentBalance };
    }

    const variables = {
        token: null,
        accountId,
        amount: paymentAmount,
    };

    return processStripe(chargeBillingMethod, 'chargeBillingMethod', variables, stripe);
}

type PaymentState = 'pre' | 'init' | 'taken';

export const TakePayment = ({ accountId, currentBalance, billingData, billingDataStatus, }: TakePaymentProps): React$Node => {
    const stripe = useStripe();
    const [state, setState] = useState<PaymentState>('pre');
    const [progress, setProgress] = useState<boolean>(false);
    const [paymentAmount, setPaymentAmount] = useState<string>('' + currentBalance);
    const [errors, setErrors] = useState<Array<string>>([]);

    const [chargeBillingMethod, { data, }] = useAuthedMutation(CHARGE_BILLING_METHOD_MUTATION, {
        client: apiClient,
        refetchQueries: ['AccountBillingData'],
    });

    const handleTakePayment = async () => {
        setErrors([]);
        setProgress(true);
        let result = await takePayment(stripe, accountId, currentBalance, paymentAmount, chargeBillingMethod);

        if (result.error != null) {
            setErrors([result.error]);
        } else {
            toast('Payment processed successfully', { type: 'success' });
            historyBack();
        }

        setProgress(false);
    };

    // if there is an outstanding balance, but we are in the default state
    // of showing '0' in the input, populate with the full outstanding balance.
    if (!isNetworkRequestInFlight(billingDataStatus) && state === 'pre') {
        setState('init');
        const params = new URLSearchParams(window.location.search);
        const amountFloat = parseFloat(params.get('amount'));

        setPaymentAmount(
            process.env.NODE_ENV === 'development'
                ? !isNaN(amountFloat) ? ('' + amountFloat) : '0.30'
                : !isNaN(amountFloat) ? ('' + amountFloat) : formatGbp(currentBalance)
        );
    }


    return (
        <Panel>
            <PanelHeader title='Make a Payment'/>
            <PanelBar>
                {!isNetworkRequestInFlight(billingDataStatus) && currentBalance === 0
                    ? <Notice>There is no outstanding balance on your account.</Notice>
                    : <>
                        {state === 'pre'
                            ? <SkeletonBar size='lg'/>
                            : <TextInput
                                preText='£'
                                label='Amount'
                                value={paymentAmount}
                                onChange={setPaymentAmount}
                                disabled={progress}
                                className='w-40'
                            />
                        }
                        {!isNetworkRequestInFlight(billingDataStatus)
                            ? <Notice icon='info-fill'>
                                Payment will be taken from your {billingData?.account?.paymentCard?.type} card
                                ending <b>{billingData?.account?.paymentCard?.number.substr(-4)}</b>
                                <Link to='../card/' className='ml-3'>
                                    <Button size='sm' kind='bare'>Change card?</Button>
                                </Link>
                            </Notice>
                            : null
                        }
                        <ErrorList errors={errors}/>
                        {data && data.chargeBillingMethod.isComplete
                            ? <Notice type='info' icon='thumb'>Payment processed successfully – thank you</Notice>
                            : null
                        }
                    </>
                }
            </PanelBar>
            <PanelButtonBar
                cacheStatus={progress ? RC_API_REQUEST : RC_CACHED}
                primaryButton={{
                    onClick: progress ? null : () => {
                        // noinspection JSIgnoredPromiseFromCall
                        handleTakePayment();
                    },
                    children: 'Make Payment',
                }}
                cancel={historyBack}
            />
        </Panel>
    );
};
