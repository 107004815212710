// @flow
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import { RC_API_REQUEST } from '../../../../state/resource/type';
import { Notice } from '../../../element/Notice';
import { useEnableTwoFactorAuth } from '../../../hoc/User';

import type { TwoFactorEditor } from '../../../hoc/User';
import type { BbUser, } from '../../../../api/type';

export type TwoFactorProps = {
    +editor: TwoFactorEditor,
    +user: BbUser,
    +userLoading: boolean,
};

const EnableTwoFactorAuth = ({ editor, user, }: TwoFactorProps): React$Node => {
    const { value: code, setValue: setCode } = editor;
    const qrImgData = useEnableTwoFactorAuth(user);

    return (
        <PanelBar>
            <p className='mb-4'>
                Scan the QR code below with your OTP application and confirm
                the displayed code.
            </p>

            <img className='w-56 h-56 border rounded-lg bg-white' src={qrImgData} alt='Loading...'/>

            <TextInput
                autoFocus={true}
                value={code || ''}
                label='Authentication Code'
                className='w-56'
                onChange={(value: string) => setCode(value)}
            />
            {editor.errors
                ? <Notice type='error'>{editor.errors}</Notice>
                : null
            }
        </PanelBar>
    );
};

export const TwoFactorAuthentication = (props: TwoFactorProps): React$Node => {
    const { user, editor, userLoading } = props;
    const loading = userLoading || editor.messages?.status === RC_API_REQUEST;

    return (
        <Panel>
            <PanelHeader title='Two-Factor Authentication'/>
            {user['2fa'].enabled
                ? null /* should never get here, this is handled by a dialog now */
                : <EnableTwoFactorAuth {...props} />
            }
            {user['2fa'].enabled
                ? null /* should never get here, this is handled by a dialog now */
                : <PanelButtonBar
                    primaryButton={{
                        onClick: loading ? null : editor.onSave,
                        children: 'Next',
                    }}
                    cancel={loading ? null : editor.onCancel}
                    cacheStatus={editor.messages?.status}
                />
            }
        </Panel>
    );
};
