// @flow

import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../Panel';
import { TextInput } from '../Input';
import { Textarea } from '../Textarea';

import type { BbAllResourceTypes } from '../../../api/type';
import type { EditorModal } from '../../common/Editor';
import type { PanelButtonBarProps } from '../Panel';

type TextEditors<R: BbAllResourceTypes> = EditorModal<string, $ReadOnlyArray<string>, R>
    | EditorModal<string | number, $ReadOnlyArray<string>, R>
    | EditorModal<string | number, string, R>
    | EditorModal<string | number, null, R>
    | EditorModal<string, null, R>
    | EditorModal<string, ?string, R>
;

type TextSettingProps<R: BbAllResourceTypes> = {
    +title: string,
    +description?: string,
    +label?: string,
    +optional?: boolean,
    +editor: TextEditors<R>,
    +children?: ?React$Node,
    +textarea?: boolean,
    +helperText?: ?string,
    +postText?: ?string,
    +className?: ?string,
    +hint?: ?React$Node,
    +leftButton?: $PropertyType<PanelButtonBarProps, 'leftButton'>,
}

export const TextSetting = <R: BbAllResourceTypes>({ title, description, label, children, helperText, postText, textarea, editor, className, hint, leftButton }: TextSettingProps<R>): React$Node => {
    const { value, setValue, errors, } = editor;
    let errorText: ?string = null;

    if (Array.isArray(errors) && errors.length) errorText = errors[0];
    if (typeof errors === 'string') errorText = errors;

    if (editor.messages && editor.messages.messages.length) {
        errorText = editor.messages.messages[0];
    }

    return (
        <Panel>
            <PanelHeader title={title} description={description} />
            <PanelBar>
                {children}
                {textarea
                    ? <Textarea
                        label={label}
                        value={value || ''}
                        onChange={setValue}
                        errorText={errorText}
                        autoFocus={true}
                    />
                    : <TextInput
                        label={label}
                        value={value || ''}
                        onChange={setValue}
                        errorText={errorText}
                        helperText={helperText}
                        postText={postText}
                        autoFocus={true}
                        hint={hint}
                        className={className}
                    />
                }
            </PanelBar>
            <PanelButtonBar
                leftButton={leftButton}
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};