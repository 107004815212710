// @flow
import { createSelector } from 'reselect';
import { createSearchAction, getSearchSelectors } from 'redux-search';
import { resourceSelector } from '../../../state/Search/search';
import { TextInput } from '../../element/Input';
import { ValueSelect } from '../../element/ValueSelect';

import type { Dispatch } from 'redux';
import type { BbCollaboration, } from '../../../api/type';
import type { CloudGuiState } from '../../../state/cloudgui';
import type { SearchEditorHook } from '../../element/Search';


const { result } = getSearchSelectors({ resourceName: 'collaboration', resourceSelector });

const getSearchTextCollaboration = createSelector(
    [result,],
    (result) => ({
        searchMatchIds: result,
    }));

const collaborationSearchText = createSearchAction('collaboration');

export const collaborationSearchDef = {
    name: 'collaboration',
    fields: [
        {
            searchFieldName: 'searchText',
            matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCollaboration>): boolean => {
                const { searchMatchIds, } = (getSearchTextCollaboration(state): { searchMatchIds: Array<string> });
                return searchMatchIds.findIndex(x => x === item.id) !== -1;
            },
            setValue: (nextValue: string, dispatch: Dispatch<any>) => {
                dispatch(collaborationSearchText(nextValue));
            },
        },
        {
            searchFieldName: 'status',
            matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCollaboration>): boolean => {
                switch(fieldValue) {
                case 'accepted':
                case 'pending':
                    return item.status === fieldValue;
                case 'cancelled':
                    return item.status === 'cancelled' || item.status === 'ended' || item.status === 'rejected';
                default:
                    return true;
                }
            },
        },
    ],
};

const statusOptions = [
    { label: 'Show all', value: ''},
    { label: 'Show accepted', value: 'accepted'},
    { label: 'Show pending', value: 'pending'},
    { label: 'Show cancelled', value: 'cancelled'},
]

export const CollaborationSearch = ({ editor }: { editor: SearchEditorHook }): React$Node => {
    const { fields, setSearchValue,  } = editor;
    const post = editor.fields.searchText.value
        ? { postIcon: 'cross', onPostInlayClick: () => editor.setSearchValue('searchText', ''), }
        : { postIcon: 'spacer', };

    return (
        <>
            <TextInput
                size='sm'
                label='Filter by keyword'
                value={fields.searchText.value}
                onChange={(value) => setSearchValue('searchText', value)}
                preIcon={'search'}
                {...post}
            />
            <ValueSelect 
                selected={fields.status.value} 
                options={statusOptions} 
                onSelect={(v: string) => setSearchValue('status', v)}
                size='sm'
            />
        </>
    );
}