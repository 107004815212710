// @flow
import { RC_CACHED } from '../../state/resource/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { BbEvent } from '../../api/type';
import type { BbCollectedAccount } from '../../api/type.acc';
import type { Store } from 'redux';

const EVENTS_WAIT_TIMEOUT = 5000;
const ACCOUNT_POLL_INTERVAL = 1000;
const ACTIVATED_WAIT_TIMEOUT = 10000;

export function waitForEventsConnected(store: Store<CloudGuiState, any, any>): Promise<boolean> {
    return new Promise(resolve => {
        let initial = store.getState().Auth.eventsConnected;

        if (initial) {
            resolve(true);
            return;
        }

        let unsub: ?(() => void) = store.subscribe(() => {
            if (store.getState().Auth.eventsConnected && unsub) {
                unsub();
                unsub = null;
                resolve(true);
            }
        });

        setTimeout(() => {
            if (unsub) {
                unsub();
                unsub = null;
                resolve(false);
            }
        }, EVENTS_WAIT_TIMEOUT);
    });
}

export function waitForAccountEvent(accountId: string, store: Store<CloudGuiState, any, any>): Promise<boolean> {
    return new Promise(resolve => {
        // start the account poll by fetching the account
        store.dispatch({ type: 'RESOURCE_FETCH_FULL', payload: { kind: 'account', id: accountId } });

        let poll: ?TimeoutID = null;

        let unsub: ?(() => void) = store.subscribe(() => {
            const { Resource } = store.getState();

            const activatedEvent = Object.keys(Resource.event.collected).find(
                (k: string) => {
                    const event: BbEvent = Resource.event.collected[k];
                    return event.resource.id === accountId && event.action === 'activated';
                }
            );
            const activatedAccount = Object.keys(Resource.account.collected).find(
                (k: string) => {
                    const account: BbCollectedAccount = Resource.account.collected[k];
                    return account.status === 'active' && account.id === accountId;
                }
            );

            if ((activatedEvent || activatedAccount) && unsub) {
                unsub();
                unsub = null;
                if (poll != null) clearTimeout(poll);
                resolve(true);
            } else if (Resource.account.cacheStatus.get(accountId) === RC_CACHED && poll == null) {
                // every time account goes back to cached, trigger another fetch ACCOUNT_POLL_INTERVAL later
                poll = setTimeout(() => {
                    poll = null;
                    store.dispatch({ type: 'RESOURCE_FETCH_FULL', payload: { kind: 'account', id: accountId } });
                }, ACCOUNT_POLL_INTERVAL);
            }
        });

        setTimeout(() => {
            if (unsub) {
                unsub();
                unsub = null;
                if (poll != null) clearTimeout(poll);
                resolve(false);
            }
        }, ACTIVATED_WAIT_TIMEOUT);
    });
}