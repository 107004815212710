// @flow

import { parseIsoDate } from '../lib/date';
import type {
    AccessControlTarget,
    BbCollaboration,
    BbCollectedResourceTypes,
    BbEvent,
    BbImage,
    BbResource,
    BbResourceKind,
    BbServerGroup,
    BbTest,
    BbTestCollected,
    BbUser,
    BbZone,
    BbNestedUser,
    BbNestedServerGroup,
    BbShortResourceRef,
} from './type';
import { BbResourceKinds } from './type';
import {
    accountsUrl,
    applicationsUrl,
    clientsUrl,
    cloudIpsUrl,
    collaborationsUrl,
    databaseServersUrl,
    databaseSnapshotsUrl,
    databaseTypesUrl,
    eventsUrl,
    firewallPoliciesUrl,
    firewallRulesUrl,
    imagesUrl,
    lbasUrl,
    serverGroupsUrl,
    serversUrl,
    serverTypesUrl,
    usersUrl,
    zonesUrl,
    volumesUrl
} from './url';
import md5 from 'md5';
import { resourceIdFullMatch } from '../component/common/AccessControl';
import { getResourceKind } from './lib';

import type { BbCollectedDatabaseServer, BbDatabaseServer, BbDatabaseSnapshot } from './type.dbs';
import type { BbAcmeCert, BbAcmeState, BbCertificateState, BbCollectedLba, BbLba } from './type.lba';
import type { BbCollectedFirewallPolicy, BbFirewallPolicy, BbFirewallRule, BbNestedFirewallPolicy, BbNestedFirewallRule } from './type.fwp';
import type { BbApplication, BbApplicationStatus, BbCollectedApplication } from './type.app';
import type { BbAccount, BbCollectedAccount } from './type.acc';
import type { BbApiClient } from './type.cli';
import type { BbCollectedServer, BbNestedServer, BbServer } from './type.srv';
import type { BbVolume } from './type.volume';

export type BbApiRawZone = {
    ...BbResource<'zone'>,
    url: string,
    handle: string,
}

type BbApiRawUser = {
    ...$Diff<$Shape<BbUser>, { gravatar_url: any }>,
    created_at: string,
};

type BbApiRawNestedUser = {
    ...$Diff<$Shape<BbNestedUser>, { gravatar_url: any, }>,
    email?: string,
    email_address?: string,
};

type BbApiRawNestedServerGroup = {
    ...$Shape<BbNestedServerGroup>,
    created_at: string,
}

type BbApiRawServer = {
    ...$Shape<BbServer>,
    zone: BbApiRawZone,
    created_at: string,
    deleted_at: ?string,
    started_at: ?string,
    console_token_expires: ?string,
    server_groups: Array<BbApiRawNestedServerGroup>,
    snapshots_schedule_next_at: ?string,
}

type BbApiRawCollectedServer = {
    ...$Shape<BbCollectedServer>,
    created_at: string,
    deleted_at: ?string,
    started_at: ?string,
}

type BbApiRawNestedServer = {
    ...$Shape<BbNestedServer>,
    created_at: string,
    deleted_at: ?string,
    started_at: ?string,
}

type BbApiNestedFirewallPolicy = {
    ...$Shape<BbNestedFirewallPolicy>,
    created_at: string,
};

type BbApiRawServerGroup = {
    ...$Shape<BbServerGroup>,
    created_at: string,
    firewall_policy: ?BbApiNestedFirewallPolicy,
};

type BbApiRawImage = {
    ...$Shape<BbImage>,
    created_at: string;
};


type BbApiRawShortResourceDef = {
    ...BbShortResourceRef,
    resource_type: BbResourceKind | 'ip_address' | null,
}

type BbApiRawEvent = {
    ...$Shape<BbEvent>,
    user?: ?BbApiRawNestedUser,
    short_action: ?string,
    created_at: string,
    resource: BbApiRawShortResourceDef,
    affects: Array<BbApiRawShortResourceDef>,
}

type BbApiRawLbaFull = {
    ...BbLba,
    certificate: ?{
        ...$Shape<BbCertificateState>,
        valid_from: string,
        expires_at: string,
    },
    acme: ?{
        ...$Shape<BbAcmeState>,
        certificate: ?{
            ...$Shape<BbAcmeCert>,
            issued_at: string,
            expires_at: string,
        },
    },
    nodes: Array<BbApiRawNestedServer>,
    created_at: string,
    deleted_at: ?string,
}

type BbApiRawLbaCollected = {
    ...$Shape<BbCollectedLba>,
    created_at: string,
    deleted_at: ?string,
};

type BbApiNestedFirewallRule = {
    ...$Shape<BbNestedFirewallRule>,
    created_at: string,
};

type BbApiRawCollectedFirewallPolicy = {
    ...$Shape<BbCollectedFirewallPolicy>,
    created_at: string,
    rules: Array<BbApiNestedFirewallRule>
};

type BbApiRawFirewallPolicy = {
    ...$Shape<BbFirewallPolicy>,
    created_at: string,
    rules: Array<BbApiNestedFirewallRule>,
};

type BbApiRawCollectedDatabaseServer = {
    ...$Shape<BbCollectedDatabaseServer>,
    zone: BbApiRawZone,
    created_at: string,
    updated_at: string,
    deleted_at: ?string,
};

type BbApiRawDatabaseServer = {
    ...$Shape<BbDatabaseServer>,
    zone: BbApiRawZone,
    created_at: string,
    updated_at: string,
    deleted_at: ?string,
    snapshots_schedule_next_at: ?string,
};

type BbApiRawDatabaseSnapshot = {
    ...$Shape<BbDatabaseSnapshot>,
    created_at: string,
    updated_at: string,
}

type BbApiRawApplication<+T> = {
    ...$Diff<$Shape<T>, { status: BbApplicationStatus }>,
    created_at: string,
    updated_at: string,
    revoked_at: ?string,
}

type BbApiRawAccount = {
    ...$Shape<BbAccount>,
    owner: BbApiRawNestedUser,
    verified_at: ?string,
    created_at: string,
};

type BbApiRawCollectedAccount = $Shape<BbCollectedAccount>;

type BbApiRawClient = {
    ...$Shape<BbApiClient>,
    revoked_at: ?string,
}

type BbApiCollaboration = {
    ...$Shape<BbCollaboration>,
    user: ?BbApiRawNestedUser,
    inviter: BbApiRawNestedUser,
    created_at: string,
    started_at: ?string,
    finished_at: ?string,
};

export const adaptServer = (data: BbApiRawServer): BbServer => {
    const { created_at, deleted_at, started_at, console_token_expires, zone, server_groups, snapshots_schedule_next_at, ...rest } = data;

    return ({
        ...rest,
        pristine: true,
        zone: zone ? adaptZone(zone) : null,
        // this is a bit ugly - we should always have a valid created_at
        // from the API, but strictly speaking parseIsoDate can fail parsing,
        // so just put an obviously-bad fallback value in
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        deleted_at: deleted_at ? parseIsoDate(deleted_at) : null,
        started_at: started_at ? parseIsoDate(started_at) : null,
        console_token_expires: console_token_expires ? parseIsoDate(console_token_expires) : null,
        server_groups: server_groups.map(adaptNestedServerGroup),
        snapshots_schedule_next_at: snapshots_schedule_next_at ? parseIsoDate(snapshots_schedule_next_at) : null,
    }: BbServer);
};

export const adaptCollectedServer = (data: BbApiRawCollectedServer): BbCollectedServer => {
    const { created_at, deleted_at, started_at, ...rest } = data;

    return {
        ...rest,
        pristine: true,
        // this is a bit ugly - we should always have a valid created_at
        // from the API, but strictly speaking parseIsoDate can fail parsing,
        // so just put an obviously-bad fallback value in
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        deleted_at: deleted_at ? parseIsoDate(deleted_at) : null,
        started_at: started_at ? parseIsoDate(started_at) : null,
    };
};

export const adaptNestedServer = (data: BbApiRawNestedServer): BbNestedServer => {
    const { created_at, deleted_at, started_at, ...rest } = data;

    return {
        ...rest,
        // this is a bit ugly - we should always have a valid created_at
        // from the API, but strictly speaking parseIsoDate can fail parsing,
        // so just put an obviously-bad fallback value in
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        deleted_at: deleted_at ? parseIsoDate(deleted_at) : null,
        started_at: started_at ? parseIsoDate(started_at) : null,
    };
}

const adaptNestedServerGroup = (data: BbApiRawNestedServerGroup): BbNestedServerGroup => {
    const { created_at, ...rest } = data;

    return {
        ...rest,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
    }
}

export const adaptServerGroup = (data: BbApiRawServerGroup): BbServerGroup => {
    const { created_at, firewall_policy, ...rest } = data;

    let fwp: ?BbNestedFirewallPolicy = null;
    if (firewall_policy) {
        let { created_at: fwp_created_at, ...fwpRest } = firewall_policy;
        fwp = {
            ...fwpRest,
            created_at: parseIsoDate(fwp_created_at) || new Date('1970-01-01 00:00'),
        }
    }

    return ({
        ...rest,
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        firewall_policy: fwp
    }: BbServerGroup);
};

export const adaptImage = (data: BbApiRawImage): BbImage => {
    const { created_at, ...rest } = data;

    return ({
        ...rest,
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
    }: BbImage);
};

export const adaptLbaCollected = (data: BbApiRawLbaCollected): BbCollectedLba => {
    const { created_at, deleted_at, ...rest } = data;

    return ({
        ...rest,
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        deleted_at: deleted_at ? parseIsoDate(deleted_at) : null
    }: BbCollectedLba);
};

export const adaptLbaFull = (data: BbApiRawLbaFull): BbLba => {
    const { created_at, deleted_at, certificate, acme, nodes, ...rest } = data;

    let extras: $Shape<BbLba> = {};

    if (certificate) {
        const { valid_from, expires_at, ...certRest } = certificate;
        extras['certificate'] = ({
            ...certRest,
            valid_from: parseIsoDate(valid_from) || new Date('1970-01-01 00:00'),
            expires_at: parseIsoDate(expires_at) || new Date('1970-01-01 00:00'),
        }: BbCertificateState);
    }

    if (acme) {
        const { certificate: acmeCert, ...acmeRest } = acme;

        if (acmeCert) {
            const { issued_at, expires_at, ...certRest } = acmeCert;
            extras['acme'] = ({
                ...acmeRest,
                certificate: ({
                    ...certRest,
                    issued_at: parseIsoDate(issued_at) || new Date('1970-01-01 00:00'),
                    expires_at: parseIsoDate(expires_at) || new Date('1970-01-01 00:00'),
                }: BbAcmeCert),
            }: BbAcmeState);
        } else {
            extras['acme'] = {
                ...acmeRest,
                certificate: null,
            };
        }
    }

    extras['nodes'] = nodes.map(adaptNestedServer)

    return ({
        ...rest,
        ...extras,
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        deleted_at: deleted_at ? parseIsoDate(deleted_at) : null
    }: BbLba);
};

export const adaptFirewallRuleNested = (data: BbApiNestedFirewallRule): BbNestedFirewallRule => {
    const { created_at, ...rest } = data;

    return ({
        ...rest,
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
    }: BbNestedFirewallRule);
};

export const adaptFirewallPolicyCollected = (data: BbApiRawCollectedFirewallPolicy): BbCollectedFirewallPolicy => {
    const { created_at, rules: rawRules, ...rest } = data;

    let extras = {
        rules: rawRules.map(adaptFirewallRuleNested)
    };

    return ({
        ...rest,
        ...extras,
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
    }: BbCollectedFirewallPolicy);
};

export const adaptFirewallRule = (data: BbApiNestedFirewallRule): BbFirewallRule => {
    const { created_at, ...rest } = data;

    return ({
        ...rest,
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
    }: BbFirewallRule);
};

export const adaptFirewallPolicy = (data: BbApiRawFirewallPolicy): BbFirewallPolicy => {
    const { created_at, rules: rawRules, ...rest } = data;

    let extras = {
        rules: rawRules.map(adaptFirewallRule)
    };

    return ({
        ...rest,
        ...extras,
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
    }: BbFirewallPolicy);
};

export const adaptCollectedDatabaseServer = (data: BbApiRawCollectedDatabaseServer): BbCollectedDatabaseServer => {
    const { created_at, updated_at, deleted_at, zone, ...rest } = data;

    return ({
        ...rest,
        zone: adaptZone(zone),
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        updated_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        deleted_at: deleted_at ? parseIsoDate(deleted_at) : null
    }: BbCollectedDatabaseServer);
};

export const adaptDatabaseServer = (data: BbApiRawDatabaseServer): BbDatabaseServer => {
    const { created_at, updated_at, deleted_at, snapshots_schedule_next_at, zone, ...rest } = data;

    return ({
        ...rest,
        zone: adaptZone(zone),
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        updated_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        deleted_at: deleted_at ? parseIsoDate(deleted_at) : null,
        snapshots_schedule_next_at: snapshots_schedule_next_at ? parseIsoDate(snapshots_schedule_next_at) : null,
    }: BbDatabaseServer);
};

export const adaptDatabaseSnapshot = (data: BbApiRawDatabaseSnapshot): BbDatabaseSnapshot => {
    const { created_at, updated_at, ...rest } = data;

    return {
        ...rest,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        updated_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
    };
}

export function emailToGravatarUrl(email: string): string {
    return md5(email.trim().toLowerCase())
}

export const adaptUser = (raw: BbApiRawUser): BbUser => {
    const { created_at, ...rest } = raw;

    return {
        ...rest,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        gravatar_url: emailToGravatarUrl(raw.email_address),
    }
};

export const adaptNestedUser = (raw: BbApiRawNestedUser): BbNestedUser => {
    // in the event stream from the faye connection,
    // the users come through with "email", and in
    // the API they have "email_address". So normalize
    // that here.

    const { email, email_address, ...rest } = raw;
    let e: string = '';
    if (typeof email_address === 'string') e = email_address;
    else if (typeof email === 'string') e = email;

    return {
        ...rest,
        email_address: e,
        gravatar_url: md5(e.trim().toLowerCase()),
    }
};

export const adaptApplication = <T: BbCollectedApplication | BbApplication>(data: BbApiRawApplication<T>): T => {
    const { created_at, updated_at, revoked_at, ...rest } = data;

    return {
        ...rest,
        status: (revoked_at === null ? 'active' : 'revoked'),
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        updated_at: parseIsoDate(updated_at) || new Date('1970-01-01 00:00'),
        revoked_at: revoked_at ? parseIsoDate(revoked_at) : null,
    }
};

export const adaptClient = (data: BbApiRawClient): BbApiClient => {
    const { revoked_at, ...rest } = data;

    return {
        ...rest,
        pristine: true,
        revoked_at: revoked_at ? parseIsoDate(revoked_at) : null,
    }
};

export const adaptCollaboration = (data: BbApiCollaboration): BbCollaboration => {
    const { created_at, started_at, finished_at, user, inviter, ...rest } = data;

    return {
        ...rest,
        user: user ? adaptNestedUser(user) : null,
        inviter: adaptNestedUser(inviter),
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        started_at: started_at ? parseIsoDate(started_at) : null,
        finished_at: finished_at ? parseIsoDate(finished_at) : null,
    }
};

export const adaptAccountCollected = (raw: BbApiRawCollectedAccount): BbCollectedAccount => {
    return ({
        ...raw,
        pristine: true,
    }: BbCollectedAccount);
};

export const adaptAccount = (raw: BbApiRawAccount): BbAccount => {
    const { created_at, verified_at, owner, ...rest } = raw;

    return ({
        ...rest,
        owner: adaptNestedUser(owner),
        pristine: true,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
        verified_at: verified_at ? parseIsoDate(verified_at) : null
    }: BbAccount);
};

// No adaptation needed for these resources - the api reflect exactly what we use.
export const adaptIdentity = <T: Object>(data: T): { ...T, pristine: true } => ({...data, pristine: true });

const adaptTest = (raw: Object): BbTest => ({
    id: raw.id,
    resource_type: raw.resource_type,
    pristine: true,
    nonAdapted: 'non-adapted',
    adapted: (typeof raw.adapted === 'string') ? parseIsoDate(raw.adapted) || new Date('1970-01-01 00:00') : raw.adapted,
    fullOnly: true,
});

const adaptTestCollected = (raw: Object): BbTestCollected => ({
    id: raw.id,
    resource_type: raw.resource_type,
    pristine: true,
    nonAdapted: 'non-adapted',
    adapted: parseIsoDate(raw.adapted) || new Date('1970-01-01 00:00'),
});

const eventActionRegex = new RegExp('^(' + Object.keys(BbResourceKinds).join('|') + ')_([_a-z]+)$');

export const adaptShortResource = (raw: BbApiRawShortResourceDef): BbShortResourceRef => {
    let kind: BbResourceKind;

    if (raw.resource_type === 'ip_address') {
        kind = 'cloud_ip';
    } else if (raw.resource_type == null) {
        kind = getResourceKind(raw.id);
    } else {
        kind = raw.resource_type;
    }

    return {
        ...raw,
        resource_type: kind,
    };
}

export const adaptEvent = (data: BbApiRawEvent): BbEvent => {
    let { created_at, action, short_action, user, resource, affects, ...rest } = data;

    if (!short_action && action) {
        const parsedAction = action.match(eventActionRegex);
        if (parsedAction) {
            short_action = parsedAction[2];
        } else {
            short_action = action;
        }
    }

    if (!short_action) {
        short_action = 'unknown';
    }

    return ({
        ...rest,
        resource: adaptShortResource(resource),
        pristine: true,
        resource_type: 'event',
        affects: affects.map(adaptShortResource),
        short_action,
        action,
        user: user ? adaptNestedUser(user) : null,
        created_at: parseIsoDate(created_at) || new Date('1970-01-01 00:00'),
    }: BbEvent);
};

const adaptZone = (data: BbApiRawZone): BbZone => {
    let { handle } = data;
    return {
        ...data,
        name: 'Zone ' + handle.substr(-1).toUpperCase()
    }
}

type BbApiRawVolume = {
    ...BbVolume,
    created_at: string,
    updated_at: string,
    deleted_at: ?string,
}

const adaptVolume = (raw: BbApiRawVolume): BbVolume => {
    const { created_at, updated_at, deleted_at, ...rest } = raw;

    return {
        created_at: (parseIsoDate(created_at) || new Date('1970-01-01 00:00')),
        updated_at: (parseIsoDate(updated_at) || new Date('1970-01-01 00:00')),
        deleted_at: deleted_at ? (parseIsoDate(deleted_at) || new Date('1970-01-01 00:00')) : null,
        ...rest
    }
}


export type BbResourceMeta<K: BbResourceKind, F: { ... BbResource<K> }, C: BbCollectedResourceTypes, R: Object> = {
    adapt: {
        full: (raw: R)  => F,
        collected: (raw: R) => C,
    },
    prefix: string;
    resource_type: K;
    url: string;
};

export const resourceMetas = {
    'server': {
        adapt: { full: adaptServer, collected: adaptCollectedServer },
        prefix: 'srv',
        resource_type: 'server',
        url: serversUrl,
    },
    'server_group': {
        adapt: { full: adaptServerGroup, collected: adaptServerGroup },
        prefix: 'grp',
        resource_type: 'server_group',
        url: serverGroupsUrl,
    },
    'image': {
        adapt: { full: adaptImage, collected: adaptImage },
        prefix: 'img',
        resource_type: 'image',
        url: imagesUrl,
    },
    'server_type': {
        adapt: { full: adaptIdentity, collected: adaptIdentity },
        prefix: 'typ',
        resource_type: 'server_type',
        url: serverTypesUrl,
    },
    'event': {
        adapt: { full: adaptEvent, collected: adaptEvent },
        prefix: 'evt',
        resource_type: 'event',
        url: eventsUrl,
    },
    'zone': {
        adapt: { full: adaptZone, collected: adaptZone },
        prefix: 'zon',
        resource_type: 'zone',
        url: zonesUrl,
    },
    'test': {
        adapt: { full: adaptTest, collected: adaptTestCollected },
        prefix: 'tst',
        resource_type: 'test',
        url: 'http://test.example.com',
    },

    'account': {
        adapt: { full: adaptAccount, collected: adaptAccountCollected, },
        prefix: 'acc',
        resource_type: 'account',
        url: accountsUrl,
    },
    'api_client': {
        adapt: { full: adaptClient, collected: adaptClient },
        prefix: 'cli',
        resource_type: 'api_client',
        url: clientsUrl,
    },
    'cloud_ip': {
        adapt: { full: adaptIdentity, collected: adaptIdentity },
        prefix: 'cip',
        resource_type: 'cloud_ip',
        url: cloudIpsUrl,
    },
    'database_server': {
        adapt: { full: adaptDatabaseServer, collected: adaptCollectedDatabaseServer },
        prefix: 'dbs',
        resource_type: 'database_server',
        url: databaseServersUrl,
    },
    'database_snapshot':  {
        adapt: { full: adaptDatabaseSnapshot, collected: adaptDatabaseSnapshot },
        prefix: 'dbi',
        resource_type: 'database_snapshot',
        url: databaseSnapshotsUrl,
    },
    'database_type': {
        adapt: { full: adaptIdentity, collected: adaptIdentity, },
        prefix: 'dbt',
        resource_type: 'database_type',
        url: databaseTypesUrl,
    },
    'firewall_policy': {
        adapt: { full: adaptFirewallPolicy, collected: adaptFirewallPolicyCollected, },
        prefix: 'fwp',
        resource_type: 'firewall_policy',
        url: firewallPoliciesUrl,
    },
    'firewall_rule': {
        adapt: { full: adaptFirewallRule, collected: adaptFirewallRuleNested, },
        prefix: 'fwr',
        resource_type: 'firewall_rule',
        url: firewallRulesUrl,
    },
    'load_balancer': {
        adapt: { full: adaptLbaFull, collected: adaptLbaCollected },
        prefix: 'lba',
        resource_type: 'load_balancer',
        url: lbasUrl,
    },
    'interface': null,
    'user': {
        adapt: { full: adaptUser, collected: adaptIdentity, },
        prefix: 'usr',
        resource_type: 'user',
        url: usersUrl,
    },
    'collaboration': {
        adapt: { full: adaptCollaboration, collected: adaptCollaboration },
        prefix: 'col',
        resource_type: 'collaboration',
        url: collaborationsUrl,
    },
    'application': {
        adapt: { full: adaptApplication, collected: adaptApplication },
        prefix: 'app',
        resource_type: 'application',
        url: applicationsUrl,
    },
    'volume': {
        adapt: { full: adaptVolume, collected: adaptVolume },
        prefix: 'vol',
        resource_type: 'volume',
        url: volumesUrl,
    },
    'test_unmapped': null, // should always be this way - has a specific use in tests.
};

export const stringToTarget = (val: string): AccessControlTarget => {
    const match = val.match(resourceIdFullMatch);

    if (match) {
        return {
            kind: (match[1]: any),
            value: val,
        };
    } else if (val === 'any') {
        return {
            kind: 'any',
            value: '',
        };
    } else {
        return {
            kind: 'addr',
            value: val,
        };
    }
    // we don't map "Other" here - we can't know that a grp- reference
    // is for another account here, so we rely on the AccessControlSelect
    // changing the selected value when it finally gets the list of groups.
};

