// @flow
import { Link } from 'react-router-dom';
import { formatDate, RightArrow} from '../../element/Styled';
import { Table, Td, Th, Tr } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { ResourceListPage } from '../../element/ResourceListPage';
import { dateSort, stringSort } from '../../element/Sort';
import { CollaborationSearch, collaborationSearchDef } from './CollaborationSearch';
import { ResourceChip } from '../../element/Chip';
import { RC_CACHED } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries,  } from '../../element/Skeleton';

import type { SortFields } from '../../element/Sort';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';
import type { BbCollaboration, BbCollaborationStatus } from '../../../api/type';

const collaborationSortFields: SortFields<BbCollaboration> = {
    _default: stringSort<BbCollaboration>('id'),
    id: stringSort<BbCollaboration>('id'),
    name: (a: BbCollaboration, b: BbCollaboration) => {
        return (a.user ? a.user.name : '').localeCompare(b.user ? b.user.name : '');
    },
    created_at: dateSort<BbCollaboration>('created_at'),
    status: stringSort<BbCollaboration>('status'),

    started_at: (a: BbCollaboration, b: BbCollaboration) => {
        return (a.started_at ? a.started_at.getTime() : 0) - (b.started_at ? b.started_at.getTime() : 0);
    },
};

const STATUS_LABEL: Map<BbCollaborationStatus, string> = new Map([
    [ 'pending', 'Pending' ],
    [ 'accepted', 'Accepted'],
    [ 'rejected', 'Rejected'],
    [ 'cancelled', 'Cancelled'],
    [ 'ended', 'Ended'],
    [ 'owner', 'Active' ],
]);

const CollaborationListTable = ({ items, Th: SortTh, search, totalCount, status }: ResourceSelectorTableProps<BbCollaboration>) => {
    return (
        <Table>
            <thead>
            <Tr>
                <SortTh field='id'>Name</SortTh>
                <SortTh field='status'>Status</SortTh>
                <SortTh field='created_at'>Invited At</SortTh>
                <Th>2FA enabled?</Th>
                <Th actions={true}>&nbsp;</Th>
            </Tr>
            </thead>
            <tbody>
            {items.map(collab =>
                <Tr key={collab.id}>
                    <Td resourceName={true}>
                        <ResourceChip resource={collab} />
                    </Td>
                    <Td>{STATUS_LABEL.get(collab.status) || collab.status}</Td>
                    <Td>{formatDate(collab.created_at)}</Td>
                    <Td>
                        {collab?.user?.['2fa']?.enabled
                        ? 'Yes'
                        : '-'
                        }
                    </Td>
                    <Td actions={true}>
                        {collab?.role !== 'owner'
                            ? <Link to={collab.id + '/'}><RightArrow /></Link>
                            : null
                        }
                    </Td>
                </Tr>
            )}
            {status !== RC_CACHED
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td><SkeletonBar size='md' /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td actions={true}>&nbsp;</Td>
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={6} title='Team Members' />
            <NoResourcesTr status={status} totalCount={totalCount} colSpan={6} context='list' title='Team Members' />

            </tbody>
        </Table>
    );
};

export const CollaborationList = (): React$Node => (
    <ResourceListPage
        title='Team'
        kind='collaboration'
        addButtonText='Invite a User'
        table={CollaborationListTable}
        searchDef={collaborationSearchDef}
        searchComponent={CollaborationSearch}
        sortFields={collaborationSortFields}
    />
);
