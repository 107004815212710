// @flow

import { useEffect, useState } from 'react';
import { historyBack } from '../../lib/history';
import { useRouteMatch } from 'react-router';
import { RC_CACHED, RC_SUCCESS, RC_ERROR, RC_ORBIT_CONTAINER, RC_ORBIT_OBJECTS } from '../../state/resource/type';

import type { Match } from 'react-router';
import type { UseStateHook, StateUpdateFn } from 'react-hooks';
import type { MessageHook } from '../hoc/Messages';
import type { BbAllResourceTypes, } from '../../api/type';
import type { ViewResourceProps } from '../hoc/ViewResource';
import type { ResourceFetched, ResourceCacheStatus } from '../../state/resource/type';
import type { OrbitContainerStatus } from '../../state/Orbit/type';

/**
 * 3 kinds of editor mode.
 *
 * adding a new resource, directly editing a field
 * ---
 * eg resources name, or for LBAs, selected nodes
 * they directly change the local create model
 *
 * adding a new resource, 'advanced' field
 * ---
 * eg LBA, setting buffer size
 * these copy the value from the create model
 * 'save' updates the create model with the updated value
 *
 * editing a resource, editing any field
 * ---
 * these copy the value from the resource model
 * 'save' triggers an API request
 */

export type EditorErrors<E> = {
    errors: E,
    setErrors: (e: E) => void,
    clearErrors: () => void,
}

export type EditorDirect<Val, Err> = {
    status: 'add',
    value: Val,
    setValue: (Val) => void,
    errors: Err,
    messages: null,
}

export type EditorModal<Val, Err, Res: Object> = {
    ...EditorErrors<Err>,
    status: 'edit' | false,
    editUri: string,
    messages: ?MessageHook<Res>,

    value: ?Val,
    setValue: (?Val) => void,

    onCancel: () => any,
    onSave: () => any,
}

export type Editor<Val, Err, Res: Object> = EditorDirect<Val, Err> | EditorModal<Val, Err, Res>;

export const useEditorErrors = <Err>(blankErrors: Err): EditorErrors<Err> => {
    const [errors, setErrors] = useState<Err>(blankErrors);

    return {
        errors, setErrors,
        clearErrors: () => setErrors(blankErrors),
    };
};

export const useEditorDirect = <Val, Err>(
    value: Val,
    setValue: (Val) => void,
    errors: Err
): EditorDirect<Val, Err> => {
    return {
        status: 'add',
        value,
        setValue,
        errors,
        messages: null,
    };
};

export const useEditorModal = <Val, Err, SaveVal, R: Object>(
    blankErrors: Err,
    onEdit: () => ?Val,
    onValidate: ?(v: Val) => [?Err, ?SaveVal],
    onSave: (v: SaveVal) => void,
    path: string, editUri: string,
): EditorModal<Val, Err, R> => {
    const errors = useEditorErrors<Err>(blankErrors);
    const [value, setValue] = useState<?Val>(null);
    const status = value === null ? false : 'edit';
    const { clearErrors } = errors;

    const shouldEdit = useRouteMatch(path + editUri + '/') !== null;
    useEffect(() => {
        if (shouldEdit && (status === null || status === false)) {
            const next = onEdit();
            if (next !== null) {
                clearErrors();
                setValue(next);
            }
        }
    }, [shouldEdit, status, onEdit, clearErrors]);

    return {
        status,
        editUri,
        messages: null,

        value,
        setValue: (val: (?Val => Val) | ?Val) => setValue(val),

        onCancel: () => {
            setValue(null);
            historyBack();
        },
        onSave: () => {
            if (value != null) {
                if (onValidate) {
                    const [errs, saveVal] = onValidate(value);
                    if (errs) {
                        errors.setErrors(errs);
                        return false;
                    } else if (saveVal) {
                        errors.clearErrors();
                        onSave(saveVal);
                        // setValue(null);
                        historyBack();
                        return true;
                    }
                } else {
                    onSave(((value: any): SaveVal));
                    // setValue(null);
                    historyBack();
                }
            }
            return false;
        },

        ...errors,
    };

};

type EditorState = 'initial' | 'editing' | 'saving' | 'closing' | 'frozen';

export const useMessagesDrivenEditor = <Resource, EditVal, Err>(
    errors: EditorErrors<Err>,
    editPath: string,
    resourceStatus: ResourceCacheStatus | ResourceFetched | OrbitContainerStatus,
    messages: ?MessageHook<Resource>,
    getItem: () => ?Resource,
    onEdit: (r: Resource, match: Match) => EditVal,
): [ ?EditVal, StateUpdateFn<?EditVal>, EditorState, StateUpdateFn<EditorState>] => {
    const editMatch: ?Match = useRouteMatch(editPath);
    const shouldEdit = editMatch !== null;
    const [state, setState] = useState<EditorState>('initial');
    const [value, setValue] = useState<?EditVal>(null);
    const { clearErrors } = errors;
    const clearMessages = messages ? messages.clear : null;
    const item = getItem();

    useEffect(() => {
        if (editMatch != null && state === 'initial' && (resourceStatus === RC_CACHED || resourceStatus === RC_ORBIT_CONTAINER || resourceStatus === RC_ORBIT_OBJECTS) && item) {
            setState('editing');
            clearErrors();
            const next = onEdit(item, editMatch);
            clearMessages ? clearMessages() : void 0;
            setValue(next);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldEdit, state, resourceStatus, item, onEdit]);

    useEffect(() => {
        if (!shouldEdit && (state === 'closing' || state === 'editing')) {
            setState('initial');
        }
    }, [state, shouldEdit]);

    useEffect(() => {
        if (messages && messages.status === RC_SUCCESS && state === 'saving') {
            setState('closing');
            historyBack();
        }
        if (messages && messages.status === RC_ERROR && state === 'saving') {
            setState('editing');
        }
    }, [ messages, state ]);

    return [ value, setValue, state, setState ];
}

export type EditorDef<Res: Object, EditVal, SaveVal, Err> = {
    emptyErrors: Err,
    onEdit: (x: Res) => EditVal,
    onValidate: ?(r: ViewResourceProps<Res, SaveVal>, v: EditVal) => [?Err, ?$Shape<SaveVal>],
    editUri: string,
    onSave?: (r: ViewResourceProps<Res, SaveVal>, v: SaveVal) => void,
}

export const useResourceEditorModal = <R: BbAllResourceTypes, EditVal, SaveVal: Object, Err>(
    resource: ViewResourceProps<R, SaveVal>,
    editorDef: EditorDef<R, EditVal, SaveVal, Err>,
    path: string,
): EditorModal<EditVal, Err, R> => {
    const errors = useEditorErrors<Err>(editorDef.emptyErrors);
    const clearMessages = resource.apiResult ? resource.apiResult.clear : null;
    const [ value, setValue, state, setState ] = useMessagesDrivenEditor<R, EditVal, Err>(
        errors,
        path + editorDef.editUri + '/',
        resource.status,
        resource.apiResult,
        () => resource.item,
        (item: R) => editorDef.onEdit(item),
    );

    return {
        status: state === 'editing' ? 'edit' : false,
        editUri: editorDef.editUri,
        messages: resource.apiResult,

        value,
        setValue: (val: (?EditVal => EditVal) | ?EditVal) => setValue(val),

        onCancel: () => {
            setState('initial');
            historyBack();
        },
        onSave: () => {
            if (value != null) {
                if (editorDef.onValidate) {
                    const [errs, saveVal] = editorDef.onValidate(resource, value);
                    if (errs) {
                        errors.setErrors(errs);
                    } else if (saveVal) {
                        clearMessages ? clearMessages() : void 0;
                        errors.clearErrors();
                        setState('saving');
                        editorDef.onSave
                            ? editorDef.onSave(resource, saveVal)
                            : resource.patchResource(saveVal);
                    }
                } else {
                    clearMessages ? clearMessages() : void 0;
                    setState('saving');
                    editorDef.onSave
                        ? editorDef.onSave(resource, ((value: any): SaveVal))
                        : resource.patchResource((({ [editorDef.editUri]: value }: any): SaveVal));
                }
            }
        },

        ...errors,
    };

};


export type EditorCreateDef<Create, EditVal, SaveVal, Err> = {
    emptyErrors: Err,
    onEdit: (x: Create) => EditVal,
    onValidate: ?(x: Create, v: EditVal) => [?Err, ?$Shape<SaveVal>],
    onSave: (p: UseStateHook<Create>, val: SaveVal) => void,
    editUri: string,
}

export const useCreateResourceEditorModal = <Create, Val, SaveVal, Err, Res: BbAllResourceTypes>(
    create: UseStateHook<Create>,
    editorDef: EditorCreateDef<Create, Val, SaveVal, Err>,
    path: string,
): EditorModal<Val, Err, Res> => {
    const errors = useEditorErrors<Err>(editorDef.emptyErrors);
    const [value, setValue] = useState<?Val>(null);
    const [state, setState] = useState<EditorState>('initial');
    const { clearErrors } = errors;
    const shouldEdit = useRouteMatch(path + editorDef.editUri + '/') !== null;
    const item = create[0];

    useEffect(() => {
        if (shouldEdit && state === 'initial') {
            setState('editing');
            clearErrors();
            const next = editorDef.onEdit(item);
            setValue(next);
        }
    }, [shouldEdit, state, editorDef, clearErrors, item]);

    useEffect(() => {
        if (!shouldEdit && (state === 'closing' || state === 'editing')) {
            setState('initial');
        }
        if (state === 'closing' && shouldEdit) {
            historyBack();
        }

    }, [state, shouldEdit]);

    return {
        status: state === 'editing' ? 'edit' : false,
        editUri: editorDef.editUri,
        messages: null,

        value,
        setValue: (val: (?Val => Val) | ?Val) => setValue(val),

        onCancel: () => {
            setState('initial');
            historyBack();
        },
        onSave: () => {
            if (value != null) {
                if (editorDef.onValidate) {
                    const [errs, saveVal] = editorDef.onValidate(create[0], value);
                    if (errs) {
                        errors.setErrors(errs);
                    } else if (saveVal) {
                        errors.clearErrors();
                        editorDef.onSave(create, saveVal);
                        setState('closing');
                    }
                } else {
                    editorDef.onSave(create, ((value: any): SaveVal));
                    setState('closing');
                }
            }
        },

        ...errors,
    };

};
