// @flow
import { history } from '../../../lib/history';
import { useViewResource, } from '../../hoc/ViewResource';
import { ApplicationList } from './edit/ApplicationList';
import { TwoFactorAuthentication } from './edit/TwoFactorAuthentication';
import { AccountList } from './AccountList';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTabs } from '../../element/UiStateHooks';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useResourceEditorModal } from '../../common/Editor';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { NameEditPanel } from '../../element/NameEditPanel';
import { RC_CACHED } from '../../../state/resource/type';
import { Dialog, useDialog } from '../../element/Dialog';
import { useSelector } from 'react-redux';
import { useDeleteCollaboration, usePendingCollaborations } from '../../hoc/Collaboration';
import { UserSshKeyEditor } from './edit/UserSshKeyEditor';
import { SkeletonBar } from '../../element/Skeleton';
import { TextSetting } from '../../element/Setting/TextSetting';
import { HiddenPassword } from '../../element/HiddenPassword';
import { UserPasswordEditor } from './edit/UserPasswordEditor';
import { useSecretDisplayDialog } from '../../hoc/Secret';
import { Avatar } from '../../element/Avatar';
import { initial } from '../../element/Chip';
import { useTwoFactorEditor } from '../../hoc/User';
import { SecretDisplay } from '../../element/SecretDisplay';
import { LABELS } from '../../element/ResourceLabels';
import { TooltipButton } from '../../element/Button';
import { isAbleToAcceptCollaboration } from '../../../lib/user-collaborations';

import type { Match } from 'react-router-dom';
import type { BbCollaboration, BbUser, BbUserPatchParams } from '../../../api/type';
import type { EditorDef } from '../../common/Editor';
import type { BbCollectedAccount } from '../../../api/type.acc';
import type { CloudGuiState } from '../../../state/cloudgui';

const hashMap = new Map < string, number> ([
    ['#settings', 0],
    ['#accounts', 1],
    ['#application', 2],
]);

const sshKeyDetail = (key: string) => {
    if (key !== '') {
        const [algo, pub, comment] = key.split(' ');
        return [algo, pub.substr(0, 8) + '...', comment || ''].join(' ');
    } else {
        return '';
    }
};

const nameDef: EditorDef<BbUser, string, BbUserPatchParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbUser) => res.name || '',
    onValidate: null,
};
const emailDef: EditorDef<BbUser, string, BbUserPatchParams, null> = {
    emptyErrors: null,
    editUri: 'email_address',
    onEdit: (res: BbUser) => res.email_address,
    onValidate: null,
};
const ssh_keyDef: EditorDef<BbUser, string, BbUserPatchParams, null> = {
    emptyErrors: null,
    editUri: 'ssh_key',
    onEdit: (res: BbUser) => res.ssh_key,
    onValidate: null,
};
const passwordDef: EditorDef<BbUser, [string, string], BbUserPatchParams,?string> = {
    emptyErrors: null,
    editUri: 'password',
    onEdit: (res: BbUser) => ['', ''],
    onValidate: (resource, value: [string, string]): [?string,?$Shape<BbUserPatchParams>] => {
        if (value[0] !== value[1]) {
            return ['Passwords do not match', null];
        } else if (value[0].length < 8) {
            return ['Minimum password length is 8 characters', null];
        }
        return [null, { password: value[0], }];
    },
};

// Generate the overlay for the tooltip when the user is unable to
// accept a collaboration and it is disabled.
export function generateDisabledTooltip(currentUser: BbUser, collab: BbCollaboration): ?React$Node {
    if (collab?.tfa_required && currentUser?.['2fa'].enabled === false) {
        return (
            <>
                <p>You will need to enable Two-Factor Authentication before you can accept this invitation.</p>
                <TooltipButton onClick={() => history.push('/user/two_factor')}>Enable Two-Factor</TooltipButton>
            </>
        )
    }
    return null;
};

function useAccountProps() {
    const currUserId = useSelector < CloudGuiState, string> ((state) => state.Auth.currUser ? state.Auth.currUser.id : '');
    const currentUser = useSelector < CloudGuiState, BbUser | null > ((state) => state.Auth.currUser);
    const pendingCollaborations = usePendingCollaborations();
    const deleteCollab = useDeleteCollaboration();

    const leaveDialog = useDialog < BbCollectedAccount > ([
        {
            label: 'Leave Account',
            kind: 'primary',
            color: 'red',
            onSelect: (data: ?BbCollectedAccount) => {
                if (data) {
                    deleteCollab.deleteCollaboration(currUserId, data.id);
                }
                return false;
            }
        }
    ]);

    const confirmAcceptDialog = useDialog < BbCollaboration > ([
        {
            label: 'Accept Invitation',
            kind: 'primary',
            color: 'blue',
            onSelect: (collab) => {
                if (collab) {
                    return pendingCollaborations.accept(collab);
                }
                return false;
            },
            disabled: (collab) => {
                if (collab) {
                    return !isAbleToAcceptCollaboration(currentUser, collab);
                }
                return false;
            },
            tooltip: (collab) => {
                if (collab) {
                    return generateDisabledTooltip(currentUser, collab);
                }
                return null;
            },
        },
    ]);

    const confirmDeclineDialog = useDialog < BbCollaboration > ([
        {
            label: 'Decline Invitation',
            kind: 'primary',
            color: 'red',
            onSelect: (collab) => {
                if (collab) {
                    return pendingCollaborations.reject(collab);
                }
                return false;
            },
        },
    ]);


    return {
        currUserId,
        pendingCollaborations, deleteCollab,
        leaveDialog,
        confirmAcceptDialog,
        confirmDeclineDialog,
    };

}

export const UserProfile = ({ id, match }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource < BbUser, BbUserPatchParams> ('user', id);
    const { item: user, status: cacheStatus, } = resource;
    const tabs = useTabs(hashMap);

    const name = useResourceEditorModal(resource, nameDef, match.path);
    const email = useResourceEditorModal(resource, emailDef, match.path);
    const sshKey = useResourceEditorModal(resource, ssh_keyDef, match.path);
    const password = useResourceEditorModal(resource, passwordDef, match.path);
    const { editor: twoFactor, disableDialog: disableTwoFactorDialog } = useTwoFactorEditor(match.path, user);

    const { currUserId, leaveDialog, confirmAcceptDialog, confirmDeclineDialog, pendingCollaborations, deleteCollab, } = useAccountProps();

    const twoFactorButton = user
        ? (
            user['2fa'].enabled
                ? { onClick: () => disableTwoFactorDialog.show(), children: 'Disable Two-Factor', kind: 'secondary', }
                : { onClick: () => history.push(twoFactor.editUri), children: 'Enable Two-Factor', kind: 'secondary', }
        )
        : null;

    const newPassword = useSecretDisplayDialog(id);

    return (
        <ResourceAddViewRoute
            match={match}
            dialog={
                <>
                    <Dialog
                        title='Leave Account?'
                        dialog={leaveDialog}
                        messages={deleteCollab.messages}
                        render={(data: ?BbCollectedAccount) => <div>Are you sure want you to leave account {data?.id}?</div>}
                    />
                    <Dialog
                        title='Accept Invitation?'
                        dialog={confirmAcceptDialog}
                        messages={pendingCollaborations.messages}
                        render={(collab) =>
                            <>
                                <p>Please confirm you want to accept the invitation to {collab?.account?.name}.</p>
                            </>
                        }
                    />
                    <Dialog
                        title='Decline Invitation?'
                        dialog={confirmDeclineDialog}
                        messages={pendingCollaborations.messages}
                        render={(collab) =>
                            <>
                                <p>Please confirm you want to decline the invitation to {collab?.account?.name}?</p>
                            </>
                        }
                    />
                    <Dialog
                        title='Disable Two-Factor Authentication?'
                        dialog={disableTwoFactorDialog}
                        messages={twoFactor.messages}
                        render={() =>
                            <div>
                                <p>Are you sure you want to disable Two-Factor authentication?</p>
                                <p>Any backup codes will become invalid.</p>
                            </div>
                        }
                    />
                    <Dialog
                        title='Two-Factor Backup Codes'
                        dialog={newPassword.dialog}
                        render={() =>
                            <div>
                                <p className='mb-3'>Two-Factor Authentication is now enabled. Store these backup codes somewhere safe in case you lose access to your 2FA device</p>
                                <SecretDisplay
                                    label='Backup Codes'
                                    secret={newPassword.secret}
                                />
                            </div>
                        }
                    />
                </>
            }
            view={
                <Panel>
                    <PanelHeader
                        cacheStatus={resource.status}
                        title={user?.name || ''}
                        editTitleLink={name.editUri}
                    />
                    <Tabs {...tabs}>
                        <TabList>
                            <Tab>User Settings</Tab>
                            <Tab>{LABELS.account.listTitle}</Tab>
                            <Tab>OAuth Applications</Tab>
                        </TabList>
                        <TabPanel>
                            <PanelMultiSettingsBar
                                cacheStatus={cacheStatus}
                                details={{
                                    id: user?.id,
                                }}
                                settings={[
                                    [
                                        {
                                            name: 'Email Address',
                                            summary: user ? (user.email_address) : <SkeletonBar />,
                                            route: email.editUri,
                                        },
                                        {
                                            name: 'Password',
                                            summary: user ? <HiddenPassword /> : <SkeletonBar />,
                                            settingsBtn: user
                                                ? {
                                                    onClick: () => history.push(password.editUri),
                                                    children: 'Change Password',
                                                    kind: 'secondary',
                                                }
                                                : null,
                                        },
                                        {
                                            name: 'Two-Factor Authentication',
                                            summary: user ? (user['2fa'].enabled ? 'Enabled' : 'Not Enabled') : <SkeletonBar />,
                                            settingsBtn: twoFactorButton,
                                        },
                                        {
                                            name: 'Avatar',
                                            summary: user
                                                ? <Avatar
                                                    initial={initial(user)}
                                                    colorId={user.id}
                                                    gravatar_url={user.gravatar_url}
                                                />
                                                : <SkeletonBar size='sm' />,
                                            hint: <span>Change your avatar? visit <a href="https://gravatar.com">gravatar</a><br /> (avatars are linked to your email address)</span>,
                                        },
                                        {
                                            name: 'SSH Public Key',
                                            summary: user ? (user.ssh_key ? user.ssh_key.split('\n').map(sshKeyDetail).map(k => <div key={k}>{k}</div>) : 'None') : <SkeletonBar />,
                                            hint: 'SSH keys are required for passwordless access to Linux-based cloud server images.',
                                            route: sshKey.editUri,
                                        },
                                    ]
                                ]}
                            />
                        </TabPanel>
                        <TabPanel>
                            <AccountList
                                currUserId={currUserId}
                                onLeaveDialogShow={leaveDialog.show}
                                pendingCollaborations={pendingCollaborations}
                                confirmAcceptDialog={confirmAcceptDialog.show}
                                confirmDeclineDialog={confirmDeclineDialog.show}
                            />
                        </TabPanel>
                        <TabPanel>
                            <ApplicationList />
                        </TabPanel>
                    </Tabs>
                </Panel>

            }
            editors={[
                { editor: name, render: () => <NameEditPanel editor={name} /> },
                { editor: sshKey, component: UserSshKeyEditor },
                { editor: email, render: () => <TextSetting label='Email Address' title='Email Address' editor={email} /> },
                { editor: password, render: () => <UserPasswordEditor editor={password} email_address={user?.email_address} /> },
                {
                    editor: twoFactor, render: () => user
                        ? <TwoFactorAuthentication editor={twoFactor} user={user} userLoading={cacheStatus !== RC_CACHED} />
                        : null
                },
            ]}
        />
    );
};